.accueil{
  @extend %page;
  &--active {
      @extend %page--active;
  }
}

.accueil_grid{
  @include media('<tablet'){
    display: flex;
    flex-direction: column;
  }
}

.accueil_flex{
  display: flex;
  gap: 2rem;
  @include media('<tablet'){
      flex-direction: column-reverse;
      margin-bottom: 50rem;
      gap: 0rem;
  }
}
.accueil_wrapper{
  display: grid;
  grid-template-rows: 30% 30% 30%;
  gap: 6rem;
  @include media('width<=1600px', 'height>=1200px'){
    height: 70rem;
    margin-top: 10rem;
    grid-template-rows: 50% 0% 50%;
    gap: 8rem;
  }
  @include media('<tablet'){
    display: block;
  }
}

// title text
.services_title{
  display: none;
  @include media('width<=1300px'){
      display: block;
      position: sticky;
      margin-top: 6rem;
  }
  @include media('<tablet'){
    margin-top: 2rem;
  }
}
.services_title_wrapper{
  position: relative;

  @include media('width<=1300px'){
    display: block;
    position: sticky;
    margin-top: 6rem;
  }
  @include media('<tablet'){
    margin-top: 2rem;
  }
}
.services_title_text{
  position:absolute;
  font-family: $font-family-bison-bold;
  text-align: center;
  letter-spacing:1rem;

  @include media('width<=1300px'){
    margin-top: 6rem;
    position: sticky;
  }

  @include media('<tablet'){
      margin-top: 2rem;
  }

  @include media('<=phone'){
    margin-top: 17rem;
  }
}

.services_title_text h1{
  display: none;
}

.services_title_text img {
  width: 90rem;
  height: 30rem;
  margin-left: -4rem;
  //display: none;

  @include media('<=tablet'){
    width: 70rem;
    height: 25rem;
    margin-left: 0rem;
  }
}


// fin title text

.accueil_title{
  position: relative;
  width: 108rem;
  height: 32rem;
  display: block;
  margin-top: 5rem;
  margin-left: -6rem;

  @include media('width>=2500px'){
    margin-left: -6rem;
  }

  @include media('width<1700px'){
    margin-top: 4rem;
  }

  @include media('width<=1300px'){
    display: none;
    position: sticky;
  }
}

#canvas {
  /* make the canvas wrapper fits the document */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.accueil_plane_container {
  // avec h1
  //width: 108rem;
  //height: 25rem;

  // avec logo
  width: 108rem;
  height: 35rem;
  margin-top: -1rem;

  // avec logo 2
  //width: 120rem;
  // height: 30rem;
  // margin-left:-15rem;
}

.accueil_plane_container img {
  /* center the image */
  pointer-events: none;
  /* hide the img element */
  display: none;
}

.accueil_section2 {
  position: relative;

  @include media('width<=1600px', 'height>=1200px'){
     margin-top: 20rem;
  }

  @include media('<tablet'){
    display: block;
  }
}
.accueil_section2_wrapper {
  display: flex;
  margin-left:3rem;
  margin-top: 6rem;
  gap: 7rem;

  @include media('width>=2500px'){
    margin-top: 4.5rem;
  }
  @include media('width>1920px','width<2300px'){
    margin-top: 4rem;
  }

  @include media('width<=1920px','width>1680px'){
    margin-top: 6rem;
  }

  @include media('width>=1680px','width<1700px') {
    margin-top: 14rem;
  }

  @include media('width<1700px'){
    margin-top: 6rem;
  }

  @include media('<tablet'){
    display: flex;
    flex-direction: column-reverse;
    margin-top: 5rem;
  }
}
.accueil_section2_video{
  width: 50rem;
  height:31rem;
  max-height: 31rem;
  z-index: 2;

  @include media('width>=2048px'){
    margin-top: 8rem;
  }

  @include media('width<=1300px'){
    width: 55rem;
    height:35rem;
    margin-top: 5rem;
  }

  @include media('<tablet'){
      width: 48rem;
      height: 30rem;
      margin-top: 120rem;
  }
}

.accueil_section2_link{
  position: relative;
  margin-top: auto;
  margin-bottom: auto;
  @include media('width<=1300px'){
    width: 10rem;
    z-index: 3;
    margin-bottom: 15rem;
    margin-left: -2rem;
  }

  @include media('<=phone'){
    margin-top: -45rem;
    margin-left: 50rem;
  }
}

.accueil_section2_media_image{
  margin-bottom: 5rem;
  width: 22rem;
  height: 19rem;
  @include media('width<=1300px'){
    width: 28rem;
    height:25rem;
    margin-left: -5rem;
  }
  @include media('<tablet'){
    width: 22rem;
    height: 19rem;
    margin-left: -3rem;
  }
}
.accueil_caroussel {
  position: relative;
}
.accueil_caroussel_wrapper {
  position: absolute;
  width: 45rem;
  height: 50rem;
  top: 0;
  background: transparent;
  // right: -40rem;
  overflow: auto;
  padding-top: 3rem;
  padding-left: 2rem;

  @include media('width>=2500px'){
    height: 80rem;
    right: -38rem;
  }

  @include media('width<2500px'){
    height: 80rem;
    right: -40rem;
  }

  @include media('width<2300px'){
    right: -42rem;
  }

  @include media('width<1920px', 'width>1680px' ){
    height: 75rem;
    right: -38rem;
  }

  @include media('width>=1680px','width<1700px') {
    height: 85rem;
    right: -38rem;
  }

  @include media('width<1700px'){
    height: 70rem;
  }

  @include media('width<=1600px', 'height>=1200px'){
    height: 110rem;
  }

  @include media('width<=1300px'){
    right:-55rem;
    height: 95rem;
  }

  @include media('<tablet'){
      width: 50rem;
      height: 65rem;
      top: 25rem;
      right:15%;
  }
  @include media('<phone'){
    width: 70rem;
    height: 100rem;
    top: 40rem;
    right:3%;
    padding-left: 5rem;
  }
}
.accueil_caroussel_wrapper {
  //Masque la barre de défilement dans Firefox
  scrollbar-width: none;
}
.accueil_caroussel_wrapper::-webkit-scrollbar {
  display: none;
}

.accueil_card_wrapper{
  width: 38rem;
  height: 50rem;
  margin-bottom: 7rem;
  transform: rotate(5deg);

  @include media('<phone'){
    margin-bottom: 40rem;
  }
}

.accueil_card_media{
  @include media('<tablet'){
      margin-top: 5rem;
  }
}

.accueil_card_item_image{
  width: 38rem;
  height: 50rem;

  @include media('<phone'){
    width: 57.645rem;
    height: 77.175rem;
    }
}

.accueil_card_infos{
  background-color: $color-white;
  height: 10rem;
  margin-top: -1rem;
  width: 40rem;
  padding-left:20px;
  padding-top: 1rem;
}

.accueil_card_text  {
  font-family: $font-family-helvetica-bold;
  letter-spacing: 0;
  line-height: normal;
  width: 10rem;
  left: 2.2rem;
  margin-top: 1rem;
  font-size: 1.5rem;
}
.accueil_card_line{
  font-family: $font-family-helvetica-regular;
  height: auto;
  margin-top: 0.2rem;
  filter:blur(0.5px);
  background-color: $color-black;
  width: 8rem;
  margin-left: 0rem;
}
.accueil_card_item_title{
  font-family: $font-family-bison-bold;
  color: $color-black;
  letter-spacing: 0;
  line-height: normal;
  bottom: 3rem;
  font-size: 4rem;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
}

/* animation curseur au survol d'une img du carousel*/

.cursor {
  position: absolute;
  background-color: $color-pink;
  opacity:0;
  border-radius: 100%;
  z-index: 7;
  user-select: none;
  pointer-events: none;
  width: 0;
  height: 0;
  @include media('<tablet'){
      display: none;
  }
}

.cursor.active{
  opacity: 1;
  width:100px;
  height:100px;
  background-color: $color-pink;
  background-size: cover;
  background-position: center;
  animation: box-closed-scaleIn 0.5s forwards;
}

.cursor.active .box-closed {
  animation: box-closed-scaleIn 0.5s forwards;
}

@keyframes box-closed-scaleIn {
  from {
    transform: scale(0.1);
    opacity: 1;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.cursor .box-half, .cursor .box-opened {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.box-closed {
  border-radius: 100%;
  opacity: 0;
}

.box-half {
  border-radius: 100%;
  opacity: 0;
}

.box-opened {
  border-radius: 100%;
  opacity: 0;
}

.cursor.active .box-half {
  opacity: 0;
  animation: boxOpening 0.35s 0.5s forwards;
}

@keyframes boxOpening {
  to {
    opacity: 1;
  }
}

.cursor.active .box-opened {
  opacity: 0;
  animation: boxOpened 0.25s 0.75s forwards;
}

@keyframes boxOpened {
  to {
    opacity: 1;
  }
}
