*,
*:after,
*:before {
  box-sizing: border-box;
  user-select: none;
}

/* :root {
--primary-color: #e3ff00;
--secondary-color: #000;
--base-font-size: 16px;
}
*/

html {
  @extend %cover;

  font-size: calc(100vw / 1440 * 10);
  position:fixed;
  overflow: hidden;

  @include media('<tablet'){
      font-size: calc(100vw / 750 * 10);
  }
}

body{
  @extend %cover;
  @extend %description-18;
  position: fixed;
  -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;
}

a {
  color: inherit;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}
input:focus {
  outline:none;
}
canvas {
  pointer-events: none;
  position: relative;
  z-index: z('canvas');
  -webkit-tap-highlight-color: transparent;
}

button {
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

img {
  opacity: 0;
  transition: opacity 0.4s ease;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;

  &.loaded {
    opacity: 1;
  }
}

.content {
  @extend %cover;
}
[data-animation="aboutTitle"]
[data-animation="label"],
[data-animation="paragraph"],
[data-animation="title"] {
  span {
    display: inline-block;
    overflow: hidden;
    vertical-align: top;
  }
}

[data-animation="button"] {
  display: inline-block;
  position: relative;
  white-space: nowrap;

  span {
    display: inline-block;
    transform-origin: center center 0.5em;
    vertical-align: middle;
  }
}

[data-animation="link"] {
  display: inline-block;
  position: relative;
  white-space: nowrap;

  span {
    display: inline-block;
    transform-origin: center center 0.5em;
    vertical-align: middle;
  }
}
@keyframes newScrolltxt{
  0% { transform: translateX(-200vw); }
 100% { transform: translateX(-10vw); }
}

@include media('width<=1300px'){
  @keyframes newScrolltxt{
    0% { transform: translateX(-250vw); }
   100% { transform: translateX(0vw); }
  }
}

// cursor animé en svg css

/* .Cursor_inner__1LCc1{
  transform: scale(1.02) translateZ(0px);
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50%;
  background: var(--primary-color);
}

.lottie {
  background: transparent;
  margin: 0px auto;
  outline: none;
  overflow: hidden;
  height: 44px;
  width: 44px;
} */