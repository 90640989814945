%cover {
  height: 100%;
  left: 0;
  position: absolute;
  top:  0;
  width: 100%;
}

%center {
  align-items: center;
  display: flex;
  justify-content: center;
}

@mixin ratio ($height, $width) {
  font-size: 0;
  overflow: hidden;
  position: relative;

  &:after {
    content: '';
    display: inline-block;
    padding-top: $width / $height * 100%;
    width: 100%;
  }
}
/*
@mixin placeholder {
  &.placeholder { @content; }
  &::-webkit-input-placeholder { @content; }
  &::-moz-placeholder { @content; }
  &:-moz-placeholder { @content; }
  &:-ms-input-placeholder { @content; }
}
*/
