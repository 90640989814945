.environnement {
    background-color: $color-green-sea;
    @extend %page;
    // transform: translateY(100%);
    transition: opacity 0.5s 0.5s ease, transform 0s 0.5s, visibility 0.5s 0.5s ease;

    &--active {
      @extend %page--active;

      // transform: translateY(0);
      transition-delay: 0;
    }
  }

  .environnement_wrapper {
    opacity: 0;
    transition: opacity 0.4s ease;

    .environnement--active & {
      opacity: 1;
    }
    margin-top: 4rem;
    @include media('<phone') {
      display: block;
      margin: auto;
      // padding: 15rem 0 31.5rem;
      width: 66.1rem;
    }
  }

  .environnement_title_wrapper{
    @include media('<tablet'){
      // position: sticky;
      // top: 0;
      z-index: -1;
    }
  }

  .environnement_title_text{
      position:absolute;
      font-family: $font-family-bison-bold;
      color: $color-violet-clear;
      font-size:45rem;
      width: 100%;
      white-space: nowrap;
      span {
        display: inline-block;
        -webkit-animation: newScrolltxt 40s linear infinite;
        animation: newScrolltxt 50s linear infinite;
      }
      top: 11rem;

      @include media('width>1920px'){
        top: 13rem;
      }

      @include media('width<=1300px'){
        top: 17rem;
      }

      @include media('<tablet'){
        font-size:30rem;
        top: 20rem;
      }

      @include media('<phone'){
        margin-top: 50rem;
      }
  }

  .environnement_infos_wrapper{
    @include media('<tablet'){
      position: fixed;
      width: 25rem;
      height: 10rem;
      bottom: 0;
      z-index: 1;
      // border: 1px solid black;
    }
  }

  .environnement_infos{
    position:absolute;
    font-family: $font-family-areaNormal-black;
    color: $color-violet-clear;
    line-height: normal;
    bottom: 5rem;
    margin-left: 5rem;

    @include media('<tablet'){
      font-size: 1rem;
      margin-left: 11rem;
      margin-bottom: -4rem;
    }
  }

  .strong {
    font-family: $font-family-areaNormal-semibold;
    color: $color-violet-clear;
    display: inline;
    line-height: 2rem;
    @include media('<tablet'){
      line-height: 1rem;
    }
  }

  .environnement_carousel_wrapper {
    position: relative;
    z-index: 4;
    display: flex;
    overflow-x:scroll;
    overflow-y: hidden;
    gap: 20rem;
    padding-left:  38rem ;
    margin-top: -4.5rem;
    height: 75rem;
    width: 145rem;

    @include media ('>tablet','width<=1850px') {
      width: 175rem;
    }

    @include media('<tablet'){
      flex-direction: column;
      overflow-y: scroll;
      overflow-x: hidden;
      width: 100rem;
      height: 42rem;
      padding-left: 11rem;
      display: block;
    }

    @include media('<tablet','height<=600px'){
      height: 35rem;
      gap: 40rem;
      padding-left: 20rem ;
    }
  
    @include media ('<phone','height>600px') {
      height: 35rem;
      padding-left: 6rem ;
    }
    @include media('<phone','height<=600px'){
      height: 30rem;
      padding-left: 18rem ;
    }
  }

  .environnement_carousel_wrapper {
    //Masque la barre de défilement dans Firefox
    scrollbar-width: none;
  }

  .environnement_carousel_wrapper::-webkit-scrollbar {
    display: none;
  }

  .environnement_carousel_media_image{
    width: 41vw;
    height: 81vh;

    @include media('<tablet','height<=600px'){
      margin-bottom: 3rem;
    }

    @include media('height>600px','width<=1300px'){
      width: 75rem;
      height:75rem;
    }

    @include media('height>600px', '<tablet'){
      margin-top: 5rem;
      margin-bottom: 10rem;
      width:55rem;
      height: 55rem;
    }

    @include media ('<phone') {
      margin-bottom: 2rem;
    }
  }
