.card {
    // @extend %center;
    // background-color: $color-green-sea;
    @extend %page;
    transform: translateY(100%);
    transition: opacity 0.5s 0.5s ease, transform 0s 0.5s, visibility 0.5s 0.5s ease;

    &--active {
      @extend %page--active;

      transform: translateY(0);
      transition-delay: 0;
    }
}

.card_wrapper {
    opacity: 0;
    transition: opacity 0.4s ease;

    .card--active & {
        opacity: 1;
    }
    @include media('<phone') {
        display: block;
        margin: auto;
        // padding: 15rem 0 31.5rem;
        width: 66.1rem;
    }
}
/* 
.card_title_wrapper{
    
} */

.card_title_text{
    position:absolute;
    margin-top: 10rem;
    font-family: $font-family-bison-bold;
    color: $color-eerie-black;
    font-size:45rem;
    width: 100%;
    white-space: nowrap;
    span {
      display: inline-block;
      animation: newScrolltxt 40s linear infinite;
    }

    @include media('width>1920px'){
        margin-top: 15rem;
    }

    @include media('width<=1300px'){
        margin-top: 20rem;
    }

    @include media('<tablet'){
      font-size:30rem;
    }

    @include media('width<=800px'){
        top: 0rem;
    }

    @include media('<phone'){
        margin-top: 85rem;
    }

}

.card_wrapper_caroussel{
    position: relative;
    z-index: 4;
    display: flex;
    gap: 20rem;
    padding-left:  38rem ;
    margin-top: -3rem;
    height: 75rem;
    width: 145rem;

    @include media ('>tablet','width<=1850px') {
        width: 175rem;
    }

    @include media('<=tablet'){
        flex-direction: column;
        width: 100rem;
        height: 42rem;
        padding-left: 2.5rem;
        display: block;
    }

    @include media('<tablet','height<=600px'){
        height: 35rem;
        gap: 40rem;
    }
}

.card_wrapper_form{
    background-color: $color-gris-form;
    text-align: center;
    width:55rem;
    height: 75rem;
    left: 5rem;
    position: relative;
    padding-top: 8rem;

    @include media('width>1920px','width<2600px') {
        margin-top: 5rem;
    }

    @include media('width>=1680px','width<1700px') {
        margin-top: 5rem;
      }

    @include media('width<=1300px'){
        margin-top: 10rem;
        margin-left: 0rem;
        width:60rem;
        height: 75rem;
    }

    @include media('<=tablet'){
        margin-top: 5rem;
    }

    @include media('width<=800px'){
        margin-top: 20rem;
    }

    @include media('<phone'){
        width:55rem;
        height: 75rem;
        margin-left: -2rem;
        margin-top: 15rem;
    }

}

input {
    padding: 0.3rem 0.3rem;
}

.card_form_input_nom_prenom{
    font-family: $font-family-area-semi-bold;
    background-color: $color-gris-form;
    border: none;
    border-bottom: 1px solid $color-eerie-black;
    font-size: 1.5rem;
    letter-spacing: 0;
    line-height: normal;
    width: 45rem;

    @include media('width<=1300px'){
        width:50rem;
        font-size: 1.8rem;
    }

    @include media('<=tablet'){
        width:53rem;
    }

    @include media('<phone'){
        width: 45rem;
        font-size: 2rem;
    }
}

.card_form_input_courriel{
    font-family: $font-family-area-semi-bold;
    background-color: $color-gris-form;
    border: none;
    border-bottom: 1px solid $color-eerie-black;
    font-size: 1.5rem;
    letter-spacing: 0;
    line-height: normal;
    margin-top: 2rem;
    width: 45rem;

    @include media('width<=1300px'){
        width:50rem;
        font-size: 1.8rem;
    }
    @include media('<=tablet'){
        width:53rem;
    }
    @include media('<phone'){
        width: 45rem;
        font-size: 2rem;
    }
}
.card_form_input_phone{
    font-family: $font-family-area-semi-bold;
    background-color: $color-gris-form;
    border: none;
    border-bottom: 1px solid $color-eerie-black;
    font-size: 1.5rem;
    letter-spacing: 0;
    line-height: normal;
    margin-top: 2rem;
    width: 45rem;

    @include media('width<=1300px'){
        width:50rem;
        font-size: 1.8rem;
    }
    @include media('<=tablet'){
        width:53rem;
    }
    @include media('<phone'){
        width: 45rem;
        font-size: 2rem;
    }
}
.card_wrapper_form_address{
    display: flex;
    gap: 1.2rem;
    justify-content: center;

    @include media('width<=1300px'){
        margin-left: 5rem;
        width:50rem;
        font-size: 1.8rem;
    }

    @include media('<=tablet'){
        width:53rem;
        margin-left: 3.5rem;
    }
    @include media('<phone'){
        width: 45rem;
        margin-left: 5rem;
    }
}
.card_form_input_ville{
    font-family: $font-family-area-semi-bold;
    background-color: $color-gris-form;
    border: none;
    border-bottom: 1px solid $color-eerie-black;
    font-size: 1.5rem;
    letter-spacing: 0;
    line-height: normal;
    margin-top: 2rem;
    width: 22rem;

    @include media('width<=1300px'){
        width:25rem;
        font-size: 1.8rem;
    }

    @include media('<=tablet'){
        width:28rem;
    }
    @include media('<phone'){
        width: 22rem;
        font-size: 2rem;
    }
}

.card_form_input_code_postale{
    font-family: $font-family-area-semi-bold;
    background-color: $color-gris-form;
    border: none;
    border-bottom: 1px solid $color-eerie-black;
    font-size: 1.5rem;
    letter-spacing: 0;
    line-height: normal;
    margin-top: 2rem;
    width: 22rem;
    @include media('width<=1300px'){
        width:25rem;
        font-size: 1.8rem;
    }
    @include media('<=tablet'){
        width:28rem;
    }
    @include media('<phone'){
        width: 22rem;
        font-size: 2rem;
    }
}
.card_form_input_message{
    font-family: $font-family-area-semi-bold;
    background-color: $color-white;
    border: none;
    font-size: 1.5rem;
    height: 25rem;
    margin-top: 2rem;
    width: 45rem;
    padding: 0.5rem 0.5rem;
    @include media('width<=1300px'){
        width:50rem;
        height: 30rem;
        font-size: 1.8rem;
    }
    @include media('<tablet'){
        width:53rem;
        height: 25rem;
    }
    @include media('<phone'){
        height: 25rem;
        width: 45rem;
        font-size: 2rem;
    }
}

.card_form_input_message::placeholder{
    text-align: left;
    vertical-align: top;
    padding: 0.5rem 0.5rem;
    @include media('<tablet'){
       padding: 0.5rem 0.5rem;
    }
}

.card_form_input_message:focus {
    outline:none;
}

.card_form_link{
    color: $color-black;
}

.card_form_submit{
  margin-left: 32rem;
  margin-top: 1rem;
  cursor: pointer;
  font-family: $font-family-area-semi-bold;// a changer area bold
  position: relative;
  text-align: center;
  height: 4rem;
  width: 12rem;
  border-radius: 30px;
  background-color: $color-pink;
  color: $color-black;
  border: none;
  font-size: 1.5rem;
  font-weight: bolder;
  cursor: pointer;
  text-transform: uppercase;
  overflow: hidden;
  opacity: 1;

  @include media ('width<=1600px') {
    height: 5rem;
    width: 15rem;
    font-size: 2.0rem;
    margin-left: 29rem;
  }

  @include media ('width<=1300px') {
    width: 14rem;
    font-size: 1.8rem;
    margin-left: 36rem;
  }

  @include media('<=tablet'){
    margin-left: 41rem;
    height: 4rem;
    width: 12rem;
    font-size: 1.5rem;
  }
  @include media('<=phone'){
    margin-left: 26rem;
    height: 6rem;
    width: 18rem;
    font-size: 2rem;
  }
}

.card_form_submit:hover{
  opacity: 1;
  border-radius: 50%;
  height: 4rem;
  width: 12rem;
  background-color: $color-pink;
  color:$color-black;

  @include media ('width<=1600px') {
    height: 5rem;
    width: 15rem;
    font-size: 2.0rem;
  }

  @include media ('width<=1300px') {
    width: 14rem;
    font-size: 1.8rem;
  }

  @include media ('width<=1024px') {
    height: 4rem;
    width: 12rem;
    font-size: 1.5rem;
  }

  @include media ('<=phone'){
    height: 6rem;
    width: 18rem;
    font-size: 2rem;
}
}

.card_form_submit:hover .text-envoyer {
  opacity: 0;
}

.card_form_submit::before {
  content: "C'est parti! \2192 C'est parti! \2192 C'est parti! \2192 C'est parti! \2192 C'est parti! \2192 C'est parti! \2192 C'est parti! \2192 C'est parti! \2192 C'est parti! \2192";
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  position: absolute;
  animation: scrollCparti 10s linear infinite;
  opacity: 0;
  font-weight: bold;
}

.card_form_submit:hover::before {
  opacity: 1;
  visibility: visible;
  position: absolute;
}


@keyframes scrollCparti{
  0% { transform: translateX(-80%); } /* Commencez à 100% (à droite) */
  100% { transform: translateX(-20%); } /* Terminez à 0% (à gauche) */
}

.card_infos_indication {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 15rem;
    height: 7rem;
    color: $color-gray-contact;
    margin-left: 2rem;
    top: 2rem;

    @include media('width<=1300px'){
        top: 0rem;
    }
}

.card_infos_indication_textone {
    position:relative;
    font-family: $font-family-bison-bold;
    font-weight: 800;
    letter-spacing: 0;
    font-size: 2rem;
    line-height: normal;
    width: 8.313rem;
    margin-left: 1.5rem;
    
    @include media('width<=1300px'){
        font-size: 2rem;
        margin-left: 1.8rem;
    }
    @include media('<=tablet'){
        margin-left: 0.3rem;
    }
    @include media('<phone'){
        margin-left: 1.5rem;
    }
}
.card_infos_hr{
    position: relative;
    background-color: $color-gray-contact;
    height: 1px;
    font-weight: 900;
    width: 9rem;
    margin-left: 2.9rem;
    margin-top: 0.2rem;
    @include media('width<=1300px'){
        margin-left: 3.1rem;
    }
    @include media('<=tablet'){
        margin-left: 1.8rem;
    }
    @include media('<phone'){
        width: 11rem;
        margin-left: 2.8rem;
    }
}
.card_infos_indication_texttwo{
    position: relative;
    font-family: $font-family-bison-bold;
    letter-spacing: 0;
    line-height: normal;
    font-size: 5rem;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    width: 20rem;
    margin-left: 2.2rem;
    margin-top: -1rem;

    @include media('width<=1300px'){
        font-size: 4.5rem;
        margin-left: 1.8rem;
    }
    @include media('<=tablet'){
        font-size: 3rem;
        left: -3rem;
        margin-left: 0.5rem;
    }
    @include media('<=phone'){
        font-size: 5rem;
        left: -1rem;
        margin-left: 3.5rem;
    }
}

.close-button {
  position: absolute;
  top: 4rem;
  right: 5rem;
  width: 3rem;
  height: 3rem;
  cursor: pointer;

  @include media('width<=1300px'){
    top: 2rem;
  }

  @include media('<tablet'){
    right: 3rem;
  }

  @include media('<phone'){
    right: 5rem;
    top: 3rem;
  }
}

.card_button {
    @extend %description-14;

    align-items: center;
    bottom: 5rem;
    display: flex;
    height: 6rem;
    justify-content: center;
    background-color: $color-pink;
    border-radius: 50%;
    left: 50%;
    opacity: 0;
    position: absolute;
    text-transform: uppercase;
    transform: translateX(-50%);
    transition: opacity 0.4s ease;
    width: 12.4rem;
    z-index: 5;

    span {
      display: inline-block;
      line-height: 1;
      position: relative;
      vertical-align: middle;
    }

    .card--active & {
      opacity: 1;
    }

    @include media('<tablet') {
        bottom: 20rem;
        height: 4rem;
        width: 8rem;
        font-size: 1.2rem;
    }

    @include media('<phone') {
        width: 12.4rem;
        height: 6rem;
        font-size: 1.8rem;
        top: 5rem;
        transform: translateX(110%);
    }
}

.card_button_icon {
    @extend %cover;
}
