.projets{
    @extend %page;
    &--active {
        @extend %page--active;
    }
}

.projets_wrapper{
  height: 100%;
  min-width: 100%;
}

.projets_overlap_group{
    align-items: flex-end;
    background-position: 100% 100%;
    background-size: cover;
    display: flex;
    height: 100%;
    min-width: 100%;
    position: relative;
}

.projets_link_retour{
    position:absolute;
    left:1rem;
    top: 0.5rem;
    z-index: 1;
}

.btn_retour{
    text-decoration: none;
    color: $color-white;
}

.projets_title{
    position:absolute;
    font-family:$font-family-bison-bold;
    font-style: normal;
    font-weight: 300;
    font-size: 15.313rem;
    line-height: normal;
    color: $color-gray-nurse;
    top: 25rem;
    left: 7rem;
    z-index: 1;
    width: 40.671rem;
    height: 16.813rem;
    @include media('<phone') {
        top: 40%;
        font-size: 20rem;
    }
}

.projets_deco_one{
    top: 0;
    left: 0;
    pointer-events: none;
    position:absolute;
    width:37.438rem;
    height:34rem;
}

.projets_deco_two{
    pointer-events: none;
    position:absolute;
    right:0;
    bottom:0;
    width: 90rem;
    height: 44rem;
}

.projets_img_construction{
    pointer-events: none;
    position:absolute;
    left: 50rem;
    top: 10rem;
    width: 22rem;
    height: 19rem;
    @include media('<tablet') {
        top: 10%;
    }
    @include media('<phone') {
        top: 20%;
    }
}

.projets_img_arrow{
    pointer-events: none;
    position:absolute;
    top: 27rem;
    left: 2rem;
    width: 3.688rem;
    height: 4.75rem;
    @include media('<phone') {
        top: 45%;
        width: 4rem;
        height: 5rem;
    }
}
