.entete {
    @extend %cover;
    z-index: z('entete');
    padding: 1rem 3rem;
    height: 4rem;
}

.entete_wrapper {
    position:relative;
}
.entete_bar_parallel{
    position:absolute;
    z-index: 1;
    cursor: pointer;

    @include media('<=phone'){
      padding-top: 2rem;
  }
}

.entete__btnretour_link{
    position: absolute;
    right: 0;

    @include media('<tablet'){
        margin-top: 0rem;
    }
}
/*
.entete_btnretour_imageretour{
    height: 2.5rem;
    width: 6.25rem;
}
*/
/* css animation bouton retour */

.retour{
    font-family: $font-family-area-semi-bold;
    position: relative;
    text-align: center;
    height: 4rem;
    width: 12rem;
    border-radius: 30px;
    //background-color: $color-blue;
    //color: $color-white;
    background-color: $color-pink;
    color: $color-black;
    border: none;
    font-size: 1.5rem;
    font-weight: bolder;
    cursor: pointer;
    text-transform: uppercase;
    overflow: hidden;
    opacity: 1;

    @include media ('width<1600px') {
      height: 5rem;
      width: 15rem;
      font-size: 2.0rem;
    }

    @include media ('width<1300px') {
      height: 6rem;
      width: 18rem;
      font-size: 2.5rem;
    }

    @include media ('width<1024px') {
      height: 4rem;
      width: 12rem;
      font-size: 1.5rem;
    }

    @include media ('<phone'){
      height: 6rem;
      width: 18rem;
      font-size: 2rem;
  }
}

.retour:hover{
    opacity: 1;
    border-radius: 50%;
    height: 4rem;
    width: 12rem;
    background-color: $color-pink;
    color:$color-black;

    @include media ('width<1600px') {
      height: 5rem;
      width: 15rem;
      font-size: 2.0rem;
    }

    @include media ('width<1300px') {
      height: 6rem;
      width: 18rem;
      font-size: 2.5rem;
    }

    @include media ('width<1024px') {
      height: 4rem;
      width: 12rem;
      font-size: 1.5rem;
    }

    @include media ('<phone'){
      height: 6rem;
      width: 18rem;
      font-size: 2rem;
  }
}

.retour:hover .text-retour {
    opacity: 0;
}

.retour::before {
    content: "\2190 Retour \2190 Retour \2190 Retour \2190 Retour \2190 Retour \2190 Retour \2190 Retour \2190 Retour \2190 Retour";
    display: inline-block;
    white-space: nowrap;
    position: absolute;
    animation: scrollretour 10s linear infinite;
    opacity: 0;
    font-weight: bold;
  }

.retour:hover::before {
    opacity: 1;
    visibility: visible;
    position: absolute;
}


@keyframes scrollretour{
    0% { transform: translateX(-10%); }
   100% { transform: translateX(-80%); }
  }
