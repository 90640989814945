.joindre{
    @extend %page;

    &--active {
        @extend %page--active;
    }
}

.joindre_wrap {
    @extend %cover;

    overflow: hidden;
    transition: opacity 0.5s 0.5s ease;

    .joindre--open & {
      opacity: 0;
      transition-delay: 2s;
      background-color:$color-eerie-black;
    }
}

.joindre_wrapper {
    position: relative;
    @include media('<tablet'){
        position: sticky;
        top: 0;
    }
}

.joindre_wrapper_caroussel{
    visibility: hidden;
    z-index: -1;
}

.joindre_title_text{
    position:absolute;
    margin-top: 10rem;
    font-family: $font-family-bison-bold;
    color: $color-eerie-black;
    font-size:50rem;
    width: 100%;
    white-space: nowrap;
    span {
      display: inline-block;
      -webkit-animation: newScrolltxt 40s linear infinite;
      animation: newScrolltxt 50s linear infinite;
    }

    @include media('width>1920px'){
      margin-top: 12rem;
    }

    @include media('width<=1300px'){
      margin-top: 15rem;
    }

    @include media('<=tablet'){
      font-size:30rem;
      margin-top: 30rem;
    }

    @include media('<phone'){
      margin-top: 70rem;
    }
}

.joindre_wrapper_caroussel{
    position: relative;
    z-index: 4;
    display: flex;
    align-items: center;
    overflow-x:scroll;
    margin-right: auto;
    gap: 20rem;
    height: 100%;
    padding-left:  38rem;
    padding-right:  38rem;

    @include media('<tablet'){
        flex-direction: column;
        overflow-y: scroll;
        overflow-x: hidden;
        padding-left: 11rem;
        padding-right: 0rem;
        display: block;
    }

    @include media('<tablet','height<=600px'){
      padding-left: 20rem;
    }
    @include media ('<phone') {
      padding-left: 10rem ;
    }
}
.joindre_wrapper_caroussel::-webkit-scrollbar {
    display: none;
}

.joindre_card_item_image {
    width: 41vw;
    height: 81vh;

    @include media('<tablet','height<=600px'){
      margin-bottom: 3rem;
    }

    @include media('height>600px','width<=1300px'){
      width: 75rem;
      height:75rem;
    }

    @include media('height>600px', '<tablet'){
      margin-top: 5rem;
      margin-bottom: 10rem;
      width:55rem;
      height: 55rem;
    }

    @include media ('<phone') {
      margin-bottom: 2rem;
    }
}
