@font-face {
  font-family: $font-george-x;
  src: url('../fonts/george-x-regular.woff2') format('woff2'),
      url('../fonts/george-x-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: $font-suisse-bp;
  src: url('../fonts/suisse-bp-light.woff2') format('woff2'),
      url('../fonts/suisse-bp-light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: $font-suisse-bp-regular;
  src: url('../fonts/suisse-bp-regular.woff2') format('woff2'),
      url('../fonts/suisse-bp-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: $font-suisse-bp-ultra;
  src: url('../fonts/suisse-bp-ultra-light.woff2') format('woff2'),
      url('../fonts/suisse-bp-ultra-light.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: $font-family-bison-bold;
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/Bison-Bold.woff") format("woff"),
      url("../fonts/Bison-Bold.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: $font-family-helvetica-bold;
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/Helvetica-Bold.woff2') format('woff2'), 
      url("../fonts/Helvetica-Bold.woff") format("woff");
  font-display: swap;
}


@font-face {
  font-family: $font-family-helvetica-regular;
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/Helvetica.woff2") format("woff2"),
      url("../fonts/Helvetica.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: $font-family-helvetica_neue-regular;
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/HelveticaNeueRegular.woff2") format("woff2"),
      url("../fonts/HelveticaNeueRegular.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: $font-family-areaNormal-semibold;
  font-style: small;
  src: url("../fonts/AreaNormal-Semibold.woff2") format("woff2"),
      url("../fonts/AreaNormal-Semibold.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: $font-family-areaNormal-bold;
  font-style: normal;
  font-weight: 100;
  src: url("../fonts/AreaNormal-Bold.woff2") format("woff2"),
      url("../fonts/AreaNormal-Bold.woff") format("woff");
  font-display: swap;
}

// Area fonts

@font-face {
  font-family: $font-family-area-regular;
  font-style: small;
  src: url("../fonts/Area-Regular.woff2") format("woff2"),
      url("../fonts/Area-Regular.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: $font-family-area-bold;
  font-style: small;
  src: url("../fonts/Area-Bold.woff2") format("woff2"),
      url("../fonts/Area-Bold.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: $font-family-area-semi-bold;
  font-style: small;
  src: url("../fonts/Area-Semibold.woff2") format("woff2"),
      url("../fonts/Area-Semibold.woff") format("woff");
  font-display: swap;
}
  