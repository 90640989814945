@charset "UTF-8";
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.joindre_wrap, .card_button_icon, .entete, .transition, .preloader, .content, body, html, .sinistre, .projets, .joindre, .geologie, .error, .environnement, .card, .accueil {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.preloader_relative, .preloader {
  align-items: center;
  display: flex;
  justify-content: center;
}

/*
@mixin placeholder {
  &.placeholder { @content; }
  &::-webkit-input-placeholder { @content; }
  &::-moz-placeholder { @content; }
  &:-moz-placeholder { @content; }
  &:-ms-input-placeholder { @content; }
}
*/
.dg.ac {
  z-index: 99999 !important;
}

.card_button {
  font-size: 1.4rem;
}
@media (max-width: 1024px) {
  .card_button {
    font-size: 3rem;
  }
}

body {
  font-size: 1.8rem;
  font-weight: 300;
}
@media (max-width: 1024px) {
  body {
    font-size: 2.8rem;
  }
}

.pieds_link, .menu_group2_facebook, .menu_group2_linkedin, .menu_group2_email, .submenu_block a {
  display: inline-block;
  overflow: hidden;
  position: relative;
  vertical-align: top;
}

.pieds_link:after, .menu_group2_facebook:after, .menu_group2_linkedin:after, .menu_group2_email:after, .submenu_block a:after {
  background: currentColor;
  bottom: 0;
  content: "";
  height: 1px;
  left: 0;
  position: absolute;
  transition: transform 0.7s cubic-bezier(0.77, 0, 0.175, 1);
  width: 100%;
}

.pieds_link:hover:after, .menu_group2_facebook:hover:after, .menu_group2_linkedin:hover:after, .menu_group2_email:hover:after, .submenu_block a:hover:after {
  transform: scaleX(1);
  transform-origin: left center;
}

.pieds_link:after, .menu_group2_facebook:after, .menu_group2_linkedin:after, .menu_group2_email:after, .submenu_block a:after {
  transform: scaleX(0);
  transform-origin: right center;
}

.pieds_link, .menu_group2_facebook, .menu_group2_linkedin, .menu_group2_email, .submenu_block a {
  display: inline-block;
}
.sinistre, .projets, .joindre, .geologie, .error, .environnement, .card, .accueil {
  opacity: 0;
  visibility: hidden;
  z-index: 1;
}

.sinistre--active, .projets--active, .joindre--active, .geologie--active, .error--active, .environnement--active, .card--active, .accueil--active {
  opacity: 1;
  visibility: visible;
}

/* %vertical-titles__item {

} */
@font-face {
  font-family: "George X";
  src: url("../fonts/george-x-regular.woff2") format("woff2"), url("../fonts/george-x-regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Suisse-bp";
  src: url("../fonts/suisse-bp-light.woff2") format("woff2"), url("../fonts/suisse-bp-light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "suisse-bp-regular";
  src: url("../fonts/suisse-bp-regular.woff2") format("woff2"), url("../fonts/suisse-bp-regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "suisse-bp-ultra";
  src: url("../fonts/suisse-bp-ultra-light.woff2") format("woff2"), url("../fonts/suisse-bp-ultra-light.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Bison-Bold";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/Bison-Bold.woff") format("woff"), url("../fonts/Bison-Bold.woff2") format("woff2");
  font-display: swap;
}
@font-face {
  font-family: "Helvetica-Bold";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/Helvetica-Bold.woff2") format("woff2"), url("../fonts/Helvetica-Bold.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Helvetica-Regular";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/Helvetica.woff2") format("woff2"), url("../fonts/Helvetica.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Helvetica-Neue-Regular";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/HelveticaNeueRegular.woff2") format("woff2"), url("../fonts/HelveticaNeueRegular.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "AreaNormal-Semibold";
  font-style: small;
  src: url("../fonts/AreaNormal-Semibold.woff2") format("woff2"), url("../fonts/AreaNormal-Semibold.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "AreaNormal-Bold";
  font-style: normal;
  font-weight: 100;
  src: url("../fonts/AreaNormal-Bold.woff2") format("woff2"), url("../fonts/AreaNormal-Bold.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Area-Regular";
  font-style: small;
  src: url("../fonts/Area-Regular.woff2") format("woff2"), url("../fonts/Area-Regular.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Area-Bold";
  font-style: small;
  src: url("../fonts/Area-Bold.woff2") format("woff2"), url("../fonts/Area-Bold.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Area-Semibold";
  font-style: small;
  src: url("../fonts/Area-Semibold.woff2") format("woff2"), url("../fonts/Area-Semibold.woff") format("woff");
  font-display: swap;
}
*,
*:after,
*:before {
  box-sizing: border-box;
  user-select: none;
}

/* :root {
--primary-color: #e3ff00;
--secondary-color: #000;
--base-font-size: 16px;
}
*/
html {
  font-size: calc(100vw / 1440 * 10);
  position: fixed;
  overflow: hidden;
}
@media (max-width: 1024px) {
  html {
    font-size: calc(100vw / 750 * 10);
  }
}

body {
  position: fixed;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

a {
  color: inherit;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

input:focus {
  outline: none;
}

canvas {
  pointer-events: none;
  position: relative;
  z-index: 2;
  -webkit-tap-highlight-color: transparent;
}

button {
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

img {
  opacity: 0;
  transition: opacity 0.4s ease;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;
}
img.loaded {
  opacity: 1;
}

[data-animation=aboutTitle] [data-animation=label] span,
[data-animation=paragraph] span,
[data-animation=title] span {
  display: inline-block;
  overflow: hidden;
  vertical-align: top;
}

[data-animation=button] {
  display: inline-block;
  position: relative;
  white-space: nowrap;
}
[data-animation=button] span {
  display: inline-block;
  transform-origin: center center 0.5em;
  vertical-align: middle;
}

[data-animation=link] {
  display: inline-block;
  position: relative;
  white-space: nowrap;
}
[data-animation=link] span {
  display: inline-block;
  transform-origin: center center 0.5em;
  vertical-align: middle;
}

@keyframes newScrolltxt {
  0% {
    transform: translateX(-200vw);
  }
  100% {
    transform: translateX(-10vw);
  }
}
@media (max-width: 1300px) {
  @keyframes newScrolltxt {
    0% {
      transform: translateX(-250vw);
    }
    100% {
      transform: translateX(0vw);
    }
  }
}
/* .Cursor_inner__1LCc1{
  transform: scale(1.02) translateZ(0px);
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50%;
  background: var(--primary-color);
}

.lottie {
  background: transparent;
  margin: 0px auto;
  outline: none;
  overflow: hidden;
  height: 44px;
  width: 44px;
} */
.preloader {
  background: #2756cf;
  color: #ffffff;
  text-align: center;
  z-index: 7;
}

.preloader_relative {
  position: relative;
  height: 100%;
  width: 100%;
}

.preloader__text {
  position: absolute;
  font-family: "Bison-Bold";
  font-style: normal;
  font-weight: 300;
  font-size: 15.313rem;
  line-height: normal;
  color: #ebe8e7;
  top: 30%;
  max-width: 67rem;
  opacity: 0;
}
.preloader__text span span {
  font-family: "Bison-Bold";
  font-style: normal;
  font-weight: 300;
  font-size: 15.313rem;
  line-height: normal;
  display: inline-block;
  transform-origin: center center 0.5em;
}
@media (max-width: 1024px) {
  .preloader__text {
    top: 10rem;
  }
}
@media (max-width: 768px) {
  .preloader__text {
    top: 30%;
  }
}

.preloader__number {
  font-size: 4rem;
  bottom: 7.3rem;
  left: 50%;
  overflow: hidden;
  position: absolute;
  transform: translateX(-50%);
}
@media (max-width: 1024px) {
  .preloader__number {
    font-size: 3rem;
  }
}
@media (max-width: 768px) {
  .preloader__number {
    font-size: 2.4rem;
  }
}

.preloader__number__text {
  display: inline-block;
}

.transition {
  z-index: 6;
}

.entete {
  z-index: 4;
  padding: 1rem 3rem;
  height: 4rem;
}

.entete_wrapper {
  position: relative;
}

.entete_bar_parallel {
  position: absolute;
  z-index: 1;
  cursor: pointer;
}
@media (max-width: 769px) {
  .entete_bar_parallel {
    padding-top: 2rem;
  }
}

.entete__btnretour_link {
  position: absolute;
  right: 0;
}
@media (max-width: 1024px) {
  .entete__btnretour_link {
    margin-top: 0rem;
  }
}

/*
.entete_btnretour_imageretour{
    height: 2.5rem;
    width: 6.25rem;
}
*/
/* css animation bouton retour */
.retour {
  font-family: "Area-Semibold";
  position: relative;
  text-align: center;
  height: 4rem;
  width: 12rem;
  border-radius: 30px;
  background-color: #F1CEB5;
  color: #000000;
  border: none;
  font-size: 1.5rem;
  font-weight: bolder;
  cursor: pointer;
  text-transform: uppercase;
  overflow: hidden;
  opacity: 1;
}
@media (max-width: 1599px) {
  .retour {
    height: 5rem;
    width: 15rem;
    font-size: 2rem;
  }
}
@media (max-width: 1299px) {
  .retour {
    height: 6rem;
    width: 18rem;
    font-size: 2.5rem;
  }
}
@media (max-width: 1023px) {
  .retour {
    height: 4rem;
    width: 12rem;
    font-size: 1.5rem;
  }
}
@media (max-width: 768px) {
  .retour {
    height: 6rem;
    width: 18rem;
    font-size: 2rem;
  }
}

.retour:hover {
  opacity: 1;
  border-radius: 50%;
  height: 4rem;
  width: 12rem;
  background-color: #F1CEB5;
  color: #000000;
}
@media (max-width: 1599px) {
  .retour:hover {
    height: 5rem;
    width: 15rem;
    font-size: 2rem;
  }
}
@media (max-width: 1299px) {
  .retour:hover {
    height: 6rem;
    width: 18rem;
    font-size: 2.5rem;
  }
}
@media (max-width: 1023px) {
  .retour:hover {
    height: 4rem;
    width: 12rem;
    font-size: 1.5rem;
  }
}
@media (max-width: 768px) {
  .retour:hover {
    height: 6rem;
    width: 18rem;
    font-size: 2rem;
  }
}

.retour:hover .text-retour {
  opacity: 0;
}

.retour::before {
  content: "←Retour ←Retour ←Retour ←Retour ←Retour ←Retour ←Retour ←Retour ←Retour";
  display: inline-block;
  white-space: nowrap;
  position: absolute;
  animation: scrollretour 10s linear infinite;
  opacity: 0;
  font-weight: bold;
}

.retour:hover::before {
  opacity: 1;
  visibility: visible;
  position: absolute;
}

@keyframes scrollretour {
  0% {
    transform: translateX(-10%);
  }
  100% {
    transform: translateX(-80%);
  }
}
.menu_overlay_wrapper {
  background-color: #2756cf;
  background-size: cover;
  background-position: 100% 100%;
  height: 100%;
  width: 0;
  position: fixed;
  top: 0;
  left: 0;
  overflow-x: hidden;
  transition: 0.5s;
  z-index: 5;
}
@media (max-width: 1024px) {
  .menu_overlay_wrapper {
    background-size: 150%;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.menu_overlap_group_content {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 60rem;
  pointer-events: cursor;
  top: 2rem;
}
@media (min-width: 1921px) and (max-width: 2299px) {
  .menu_overlap_group_content {
    top: 5rem;
  }
}
@media (max-width: 1920px) and (min-width: 1701px) {
  .menu_overlap_group_content {
    top: 1rem;
  }
}
@media (min-width: 1680px) and (max-width: 1699px) {
  .menu_overlap_group_content {
    top: 10rem;
  }
}
@media (max-width: 1500px) {
  .menu_overlap_group_content {
    top: 5rem;
  }
}
@media (max-width: 1024px) {
  .menu_overlap_group_content {
    left: 20rem;
    top: 10rem;
  }
}
@media (max-width: 768px) {
  .menu_overlap_group_content {
    top: 35rem;
    left: 40rem;
    right: 50rem;
  }
}

.menu_overlay_link {
  display: flex;
  gap: 3rem;
  justify-content: center;
  transition: 0.1s;
}
@media (max-width: 1024px) {
  .menu_overlay_link {
    margin-left: -8rem;
  }
}
@media (max-width: 768px) {
  .menu_overlay_link {
    width: 50rem;
    margin-left: -26rem;
    justify-content: flex-start;
  }
}

.menu_overlay_link_services {
  display: grid;
  grid-template-rows: 10rem 2rem;
}

.menu_srv_title {
  display: flex;
  gap: 3rem;
}

.submenu_services {
  display: none;
  width: 20rem;
  justify-content: flex-start;
}
@media (max-width: 768px) {
  .submenu_services {
    margin-top: 2rem;
  }
}

.submenu_block a, .menu_accueil, .menu_services, .menu_realisations, .menu_projets, .menu_contact {
  letter-spacing: 0;
  line-height: normal;
  color: #ebe8e7;
  font-family: "Bison-Bold";
  font-style: normal;
  font-weight: 700;
  transition: 0.5s;
  text-decoration: none;
  z-index: 5;
}

.submenu_block a {
  position: relative;
  font-size: 3rem;
  left: 1rem;
}
@media (max-width: 768px) {
  .submenu_block a {
    font-size: 5rem;
  }
}

.menu_accueil, .menu_services, .menu_realisations, .menu_projets, .menu_contact {
  font-size: 12rem;
}
@media (max-width: 768px) {
  .menu_accueil, .menu_services, .menu_realisations, .menu_projets, .menu_contact {
    font-size: 15rem;
  }
}

.menu_fleche {
  width: 12rem;
  height: 7.5rem;
  margin-top: auto;
  margin-bottom: auto;
  display: none;
  pointer-events: none;
}

.menu_deco {
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 454px;
  pointer-events: none;
}
@media (max-height: 643px) {
  .menu_deco {
    height: auto;
  }
}
@media (max-width: 1024px) {
  .menu_deco {
    display: none;
  }
}

.menu_deco_phone {
  pointer-events: none;
  display: none;
}
@media (max-width: 1024px) {
  .menu_deco_phone {
    display: block;
  }
}

.menu_closebtn {
  position: absolute;
  top: 0px;
  left: 0px;
  margin-top: 0.5rem;
  margin-left: 3rem;
  cursor: pointer;
}

.menu_group2_facebook, .menu_group2_linkedin, .menu_group2_email {
  line-height: normal;
  color: #ffffff;
  font-family: "Helvetica-Neue-Regular";
  font-size: 2rem;
  font-style: normal;
  font-weight: 900;
  letter-spacing: 1px;
  text-decoration: none;
  z-index: 5;
}
@media (max-width: 768px) {
  .menu_group2_facebook, .menu_group2_linkedin, .menu_group2_email {
    font-size: 3rem;
  }
}

@media (max-width: 1024px) {
  .menu_group2_email {
    width: 6rem;
  }
}
@media (max-width: 768px) {
  .menu_group2_email {
    width: 9rem;
  }
}

@media (max-width: 1024px) {
  .menu_group2_linkedin {
    width: 10rem;
  }
}
@media (max-width: 768px) {
  .menu_group2_linkedin {
    width: 13rem;
  }
}

.menu_links_wrapper {
  display: flex;
  margin-top: 4rem;
  gap: 5rem;
}
@media (max-width: 1024px) {
  .menu_links_wrapper {
    width: 50rem;
    justify-content: center;
    margin-left: -10rem;
    text-align: center;
  }
}
@media (max-width: 768px) {
  .menu_links_wrapper {
    margin-top: 10rem;
    margin-left: -30rem;
  }
}

.menu_shape {
  pointer-events: none;
  right: 0;
  object-fit: cover;
  position: absolute;
  bottom: 0;
}
@media (min-width: 1026px) and (max-height: 643px) {
  .menu_shape {
    top: 650px;
  }
}
@media (max-width: 1024px) {
  .menu_shape {
    top: 1000px;
  }
}

.pieds {
  position: absolute;
  display: flex;
  bottom: 0rem;
}
@media (max-width: 1024px) {
  .pieds {
    bottom: 0rem;
    height: 100px;
  }
}

.pieds_links_wrapper {
  position: relative;
  margin-left: 60rem;
  display: flex;
  width: 28rem;
  height: 3rem;
  text-align: center;
  gap: 4rem;
  bottom: 0rem;
  z-index: 3;
}
@media (max-width: 1024px) {
  .pieds_links_wrapper {
    position: fixed;
    margin-left: 28rem;
    gap: 3rem;
  }
}
@media (max-width: 768px) {
  .pieds_links_wrapper {
    margin-left: 23rem;
  }
}

.pieds_link {
  color: #000000;
  font-family: "Helvetica-Neue-Regular";
  font-size: 1.7rem;
  font-style: normal;
  font-weight: 900;
  letter-spacing: 1px;
  text-decoration: none;
  z-index: 3;
  height: 1.8rem;
}
@media (max-width: 1024px) {
  .pieds_link {
    font-size: 1.3rem;
    height: 1.5rem;
  }
}
@media (max-width: 768px) {
  .pieds_link {
    font-size: 2.3rem;
    height: 2.5rem;
  }
}

.pieds_media_background_black, .pieds_media_background_white {
  pointer-events: none;
  position: fixed;
  height: 20rem;
  width: 40rem;
  right: 0;
  bottom: 0rem;
  z-index: 1;
}

/*
*  css informations disponibilités footer-reseau-common
*/
.pieds_infos_wrapper {
  z-index: 1;
}
@media (max-width: 1024px) {
  .pieds_infos_wrapper {
    position: fixed;
    width: 25rem;
    height: 10rem;
    bottom: 0;
    display: none;
  }
}

.pieds_infos {
  position: absolute;
  font-family: "AreaNormal-Black";
  color: #F5F4F5;
  line-height: normal;
  bottom: 5rem;
  margin-left: 5rem;
}
@media (max-width: 1024px) {
  .pieds_infos {
    font-size: 1.3rem;
    margin-left: 11rem;
    margin-bottom: -4rem;
  }
}
@media (max-width: 768px) {
  .pieds_infos {
    font-size: 2.3rem;
    width: 50rem;
  }
}

/*
.strong {
  font-family: $font-family-areaNormal-semibold;
  color: $color-violet-clear;
  display: inline;
  line-height: 2rem;
  @include media('<tablet'){
    line-height: 1rem;
  }
  @include media('<phone'){
    line-height: 1.8rem;
  }
}
*/
@media (max-width: 1024px) {
  .accueil_grid {
    display: flex;
    flex-direction: column;
  }
}

.accueil_flex {
  display: flex;
  gap: 2rem;
}
@media (max-width: 1024px) {
  .accueil_flex {
    flex-direction: column-reverse;
    margin-bottom: 50rem;
    gap: 0rem;
  }
}

.accueil_wrapper {
  display: grid;
  grid-template-rows: 30% 30% 30%;
  gap: 6rem;
}
@media (max-width: 1600px) and (min-height: 1200px) {
  .accueil_wrapper {
    height: 70rem;
    margin-top: 10rem;
    grid-template-rows: 50% 0% 50%;
    gap: 8rem;
  }
}
@media (max-width: 1024px) {
  .accueil_wrapper {
    display: block;
  }
}

.services_title {
  display: none;
}
@media (max-width: 1300px) {
  .services_title {
    display: block;
    position: sticky;
    margin-top: 6rem;
  }
}
@media (max-width: 1024px) {
  .services_title {
    margin-top: 2rem;
  }
}

.services_title_wrapper {
  position: relative;
}
@media (max-width: 1300px) {
  .services_title_wrapper {
    display: block;
    position: sticky;
    margin-top: 6rem;
  }
}
@media (max-width: 1024px) {
  .services_title_wrapper {
    margin-top: 2rem;
  }
}

.services_title_text {
  position: absolute;
  font-family: "Bison-Bold";
  text-align: center;
  letter-spacing: 1rem;
}
@media (max-width: 1300px) {
  .services_title_text {
    margin-top: 6rem;
    position: sticky;
  }
}
@media (max-width: 1024px) {
  .services_title_text {
    margin-top: 2rem;
  }
}
@media (max-width: 769px) {
  .services_title_text {
    margin-top: 17rem;
  }
}

.services_title_text h1 {
  display: none;
}

.services_title_text img {
  width: 90rem;
  height: 30rem;
  margin-left: -4rem;
}
@media (max-width: 1025px) {
  .services_title_text img {
    width: 70rem;
    height: 25rem;
    margin-left: 0rem;
  }
}

.accueil_title {
  position: relative;
  width: 108rem;
  height: 32rem;
  display: block;
  margin-top: 5rem;
  margin-left: -6rem;
}
@media (min-width: 2500px) {
  .accueil_title {
    margin-left: -6rem;
  }
}
@media (max-width: 1699px) {
  .accueil_title {
    margin-top: 4rem;
  }
}
@media (max-width: 1300px) {
  .accueil_title {
    display: none;
    position: sticky;
  }
}

#canvas {
  /* make the canvas wrapper fits the document */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.accueil_plane_container {
  width: 108rem;
  height: 35rem;
  margin-top: -1rem;
}

.accueil_plane_container img {
  /* center the image */
  pointer-events: none;
  /* hide the img element */
  display: none;
}

.accueil_section2 {
  position: relative;
}
@media (max-width: 1600px) and (min-height: 1200px) {
  .accueil_section2 {
    margin-top: 20rem;
  }
}
@media (max-width: 1024px) {
  .accueil_section2 {
    display: block;
  }
}

.accueil_section2_wrapper {
  display: flex;
  margin-left: 3rem;
  margin-top: 6rem;
  gap: 7rem;
}
@media (min-width: 2500px) {
  .accueil_section2_wrapper {
    margin-top: 4.5rem;
  }
}
@media (min-width: 1921px) and (max-width: 2299px) {
  .accueil_section2_wrapper {
    margin-top: 4rem;
  }
}
@media (max-width: 1920px) and (min-width: 1681px) {
  .accueil_section2_wrapper {
    margin-top: 6rem;
  }
}
@media (min-width: 1680px) and (max-width: 1699px) {
  .accueil_section2_wrapper {
    margin-top: 14rem;
  }
}
@media (max-width: 1699px) {
  .accueil_section2_wrapper {
    margin-top: 6rem;
  }
}
@media (max-width: 1024px) {
  .accueil_section2_wrapper {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 5rem;
  }
}

.accueil_section2_video {
  width: 50rem;
  height: 31rem;
  max-height: 31rem;
  z-index: 2;
}
@media (min-width: 2048px) {
  .accueil_section2_video {
    margin-top: 8rem;
  }
}
@media (max-width: 1300px) {
  .accueil_section2_video {
    width: 55rem;
    height: 35rem;
    margin-top: 5rem;
  }
}
@media (max-width: 1024px) {
  .accueil_section2_video {
    width: 48rem;
    height: 30rem;
    margin-top: 120rem;
  }
}

.accueil_section2_link {
  position: relative;
  margin-top: auto;
  margin-bottom: auto;
}
@media (max-width: 1300px) {
  .accueil_section2_link {
    width: 10rem;
    z-index: 3;
    margin-bottom: 15rem;
    margin-left: -2rem;
  }
}
@media (max-width: 769px) {
  .accueil_section2_link {
    margin-top: -45rem;
    margin-left: 50rem;
  }
}

.accueil_section2_media_image {
  margin-bottom: 5rem;
  width: 22rem;
  height: 19rem;
}
@media (max-width: 1300px) {
  .accueil_section2_media_image {
    width: 28rem;
    height: 25rem;
    margin-left: -5rem;
  }
}
@media (max-width: 1024px) {
  .accueil_section2_media_image {
    width: 22rem;
    height: 19rem;
    margin-left: -3rem;
  }
}

.accueil_caroussel {
  position: relative;
}

.accueil_caroussel_wrapper {
  position: absolute;
  width: 45rem;
  height: 50rem;
  top: 0;
  background: transparent;
  overflow: auto;
  padding-top: 3rem;
  padding-left: 2rem;
}
@media (min-width: 2500px) {
  .accueil_caroussel_wrapper {
    height: 80rem;
    right: -38rem;
  }
}
@media (max-width: 2499px) {
  .accueil_caroussel_wrapper {
    height: 80rem;
    right: -40rem;
  }
}
@media (max-width: 2299px) {
  .accueil_caroussel_wrapper {
    right: -42rem;
  }
}
@media (max-width: 1919px) and (min-width: 1681px) {
  .accueil_caroussel_wrapper {
    height: 75rem;
    right: -38rem;
  }
}
@media (min-width: 1680px) and (max-width: 1699px) {
  .accueil_caroussel_wrapper {
    height: 85rem;
    right: -38rem;
  }
}
@media (max-width: 1699px) {
  .accueil_caroussel_wrapper {
    height: 70rem;
  }
}
@media (max-width: 1600px) and (min-height: 1200px) {
  .accueil_caroussel_wrapper {
    height: 110rem;
  }
}
@media (max-width: 1300px) {
  .accueil_caroussel_wrapper {
    right: -55rem;
    height: 95rem;
  }
}
@media (max-width: 1024px) {
  .accueil_caroussel_wrapper {
    width: 50rem;
    height: 65rem;
    top: 25rem;
    right: 15%;
  }
}
@media (max-width: 768px) {
  .accueil_caroussel_wrapper {
    width: 70rem;
    height: 100rem;
    top: 40rem;
    right: 3%;
    padding-left: 5rem;
  }
}

.accueil_caroussel_wrapper {
  scrollbar-width: none;
}

.accueil_caroussel_wrapper::-webkit-scrollbar {
  display: none;
}

.accueil_card_wrapper {
  width: 38rem;
  height: 50rem;
  margin-bottom: 7rem;
  transform: rotate(5deg);
}
@media (max-width: 768px) {
  .accueil_card_wrapper {
    margin-bottom: 40rem;
  }
}

@media (max-width: 1024px) {
  .accueil_card_media {
    margin-top: 5rem;
  }
}

.accueil_card_item_image {
  width: 38rem;
  height: 50rem;
}
@media (max-width: 768px) {
  .accueil_card_item_image {
    width: 57.645rem;
    height: 77.175rem;
  }
}

.accueil_card_infos {
  background-color: #ffffff;
  height: 10rem;
  margin-top: -1rem;
  width: 40rem;
  padding-left: 20px;
  padding-top: 1rem;
}

.accueil_card_text {
  font-family: "Helvetica-Bold";
  letter-spacing: 0;
  line-height: normal;
  width: 10rem;
  left: 2.2rem;
  margin-top: 1rem;
  font-size: 1.5rem;
}

.accueil_card_line {
  font-family: "Helvetica-Regular";
  height: auto;
  margin-top: 0.2rem;
  filter: blur(0.5px);
  background-color: #000000;
  width: 8rem;
  margin-left: 0rem;
}

.accueil_card_item_title {
  font-family: "Bison-Bold";
  color: #000000;
  letter-spacing: 0;
  line-height: normal;
  bottom: 3rem;
  font-size: 4rem;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
}

/* animation curseur au survol d'une img du carousel*/
.cursor {
  position: absolute;
  background-color: #F1CEB5;
  opacity: 0;
  border-radius: 100%;
  z-index: 7;
  user-select: none;
  pointer-events: none;
  width: 0;
  height: 0;
}
@media (max-width: 1024px) {
  .cursor {
    display: none;
  }
}

.cursor.active {
  opacity: 1;
  width: 100px;
  height: 100px;
  background-color: #F1CEB5;
  background-size: cover;
  background-position: center;
  animation: box-closed-scaleIn 0.5s forwards;
}

.cursor.active .box-closed {
  animation: box-closed-scaleIn 0.5s forwards;
}

@keyframes box-closed-scaleIn {
  from {
    transform: scale(0.1);
    opacity: 1;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
.cursor .box-half, .cursor .box-opened {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.box-closed {
  border-radius: 100%;
  opacity: 0;
}

.box-half {
  border-radius: 100%;
  opacity: 0;
}

.box-opened {
  border-radius: 100%;
  opacity: 0;
}

.cursor.active .box-half {
  opacity: 0;
  animation: boxOpening 0.35s 0.5s forwards;
}

@keyframes boxOpening {
  to {
    opacity: 1;
  }
}
.cursor.active .box-opened {
  opacity: 0;
  animation: boxOpened 0.25s 0.75s forwards;
}

@keyframes boxOpened {
  to {
    opacity: 1;
  }
}
.card {
  transform: translateY(100%);
  transition: opacity 0.5s 0.5s ease, transform 0s 0.5s, visibility 0.5s 0.5s ease;
}
.card--active {
  transform: translateY(0);
  transition-delay: 0;
}

.card_wrapper {
  opacity: 0;
  transition: opacity 0.4s ease;
}
.card--active .card_wrapper {
  opacity: 1;
}
@media (max-width: 768px) {
  .card_wrapper {
    display: block;
    margin: auto;
    width: 66.1rem;
  }
}

/* 
.card_title_wrapper{

} */
.card_title_text {
  position: absolute;
  margin-top: 10rem;
  font-family: "Bison-Bold";
  color: #1e181a;
  font-size: 45rem;
  width: 100%;
  white-space: nowrap;
}
.card_title_text span {
  display: inline-block;
  animation: newScrolltxt 40s linear infinite;
}
@media (min-width: 1921px) {
  .card_title_text {
    margin-top: 15rem;
  }
}
@media (max-width: 1300px) {
  .card_title_text {
    margin-top: 20rem;
  }
}
@media (max-width: 1024px) {
  .card_title_text {
    font-size: 30rem;
  }
}
@media (max-width: 800px) {
  .card_title_text {
    top: 0rem;
  }
}
@media (max-width: 768px) {
  .card_title_text {
    margin-top: 85rem;
  }
}

.card_wrapper_caroussel {
  position: relative;
  z-index: 4;
  display: flex;
  gap: 20rem;
  padding-left: 38rem;
  margin-top: -3rem;
  height: 75rem;
  width: 145rem;
}
@media (min-width: 1026px) and (max-width: 1850px) {
  .card_wrapper_caroussel {
    width: 175rem;
  }
}
@media (max-width: 1025px) {
  .card_wrapper_caroussel {
    flex-direction: column;
    width: 100rem;
    height: 42rem;
    padding-left: 2.5rem;
    display: block;
  }
}
@media (max-width: 1024px) and (max-height: 600px) {
  .card_wrapper_caroussel {
    height: 35rem;
    gap: 40rem;
  }
}

.card_wrapper_form {
  background-color: #f4f4f5;
  text-align: center;
  width: 55rem;
  height: 75rem;
  left: 5rem;
  position: relative;
  padding-top: 8rem;
}
@media (min-width: 1921px) and (max-width: 2599px) {
  .card_wrapper_form {
    margin-top: 5rem;
  }
}
@media (min-width: 1680px) and (max-width: 1699px) {
  .card_wrapper_form {
    margin-top: 5rem;
  }
}
@media (max-width: 1300px) {
  .card_wrapper_form {
    margin-top: 10rem;
    margin-left: 0rem;
    width: 60rem;
    height: 75rem;
  }
}
@media (max-width: 1025px) {
  .card_wrapper_form {
    margin-top: 5rem;
  }
}
@media (max-width: 800px) {
  .card_wrapper_form {
    margin-top: 20rem;
  }
}
@media (max-width: 768px) {
  .card_wrapper_form {
    width: 55rem;
    height: 75rem;
    margin-left: -2rem;
    margin-top: 15rem;
  }
}

input {
  padding: 0.3rem 0.3rem;
}

.card_form_input_nom_prenom {
  font-family: "Area-Semibold";
  background-color: #f4f4f5;
  border: none;
  border-bottom: 1px solid #1e181a;
  font-size: 1.5rem;
  letter-spacing: 0;
  line-height: normal;
  width: 45rem;
}
@media (max-width: 1300px) {
  .card_form_input_nom_prenom {
    width: 50rem;
    font-size: 1.8rem;
  }
}
@media (max-width: 1025px) {
  .card_form_input_nom_prenom {
    width: 53rem;
  }
}
@media (max-width: 768px) {
  .card_form_input_nom_prenom {
    width: 45rem;
    font-size: 2rem;
  }
}

.card_form_input_courriel {
  font-family: "Area-Semibold";
  background-color: #f4f4f5;
  border: none;
  border-bottom: 1px solid #1e181a;
  font-size: 1.5rem;
  letter-spacing: 0;
  line-height: normal;
  margin-top: 2rem;
  width: 45rem;
}
@media (max-width: 1300px) {
  .card_form_input_courriel {
    width: 50rem;
    font-size: 1.8rem;
  }
}
@media (max-width: 1025px) {
  .card_form_input_courriel {
    width: 53rem;
  }
}
@media (max-width: 768px) {
  .card_form_input_courriel {
    width: 45rem;
    font-size: 2rem;
  }
}

.card_form_input_phone {
  font-family: "Area-Semibold";
  background-color: #f4f4f5;
  border: none;
  border-bottom: 1px solid #1e181a;
  font-size: 1.5rem;
  letter-spacing: 0;
  line-height: normal;
  margin-top: 2rem;
  width: 45rem;
}
@media (max-width: 1300px) {
  .card_form_input_phone {
    width: 50rem;
    font-size: 1.8rem;
  }
}
@media (max-width: 1025px) {
  .card_form_input_phone {
    width: 53rem;
  }
}
@media (max-width: 768px) {
  .card_form_input_phone {
    width: 45rem;
    font-size: 2rem;
  }
}

.card_wrapper_form_address {
  display: flex;
  gap: 1.2rem;
  justify-content: center;
}
@media (max-width: 1300px) {
  .card_wrapper_form_address {
    margin-left: 5rem;
    width: 50rem;
    font-size: 1.8rem;
  }
}
@media (max-width: 1025px) {
  .card_wrapper_form_address {
    width: 53rem;
    margin-left: 3.5rem;
  }
}
@media (max-width: 768px) {
  .card_wrapper_form_address {
    width: 45rem;
    margin-left: 5rem;
  }
}

.card_form_input_ville {
  font-family: "Area-Semibold";
  background-color: #f4f4f5;
  border: none;
  border-bottom: 1px solid #1e181a;
  font-size: 1.5rem;
  letter-spacing: 0;
  line-height: normal;
  margin-top: 2rem;
  width: 22rem;
}
@media (max-width: 1300px) {
  .card_form_input_ville {
    width: 25rem;
    font-size: 1.8rem;
  }
}
@media (max-width: 1025px) {
  .card_form_input_ville {
    width: 28rem;
  }
}
@media (max-width: 768px) {
  .card_form_input_ville {
    width: 22rem;
    font-size: 2rem;
  }
}

.card_form_input_code_postale {
  font-family: "Area-Semibold";
  background-color: #f4f4f5;
  border: none;
  border-bottom: 1px solid #1e181a;
  font-size: 1.5rem;
  letter-spacing: 0;
  line-height: normal;
  margin-top: 2rem;
  width: 22rem;
}
@media (max-width: 1300px) {
  .card_form_input_code_postale {
    width: 25rem;
    font-size: 1.8rem;
  }
}
@media (max-width: 1025px) {
  .card_form_input_code_postale {
    width: 28rem;
  }
}
@media (max-width: 768px) {
  .card_form_input_code_postale {
    width: 22rem;
    font-size: 2rem;
  }
}

.card_form_input_message {
  font-family: "Area-Semibold";
  background-color: #ffffff;
  border: none;
  font-size: 1.5rem;
  height: 25rem;
  margin-top: 2rem;
  width: 45rem;
  padding: 0.5rem 0.5rem;
}
@media (max-width: 1300px) {
  .card_form_input_message {
    width: 50rem;
    height: 30rem;
    font-size: 1.8rem;
  }
}
@media (max-width: 1024px) {
  .card_form_input_message {
    width: 53rem;
    height: 25rem;
  }
}
@media (max-width: 768px) {
  .card_form_input_message {
    height: 25rem;
    width: 45rem;
    font-size: 2rem;
  }
}

.card_form_input_message::placeholder {
  text-align: left;
  vertical-align: top;
  padding: 0.5rem 0.5rem;
}
@media (max-width: 1024px) {
  .card_form_input_message::placeholder {
    padding: 0.5rem 0.5rem;
  }
}

.card_form_input_message:focus {
  outline: none;
}

.card_form_link {
  color: #000000;
}

.card_form_submit {
  margin-left: 32rem;
  margin-top: 1rem;
  cursor: pointer;
  font-family: "Area-Semibold";
  position: relative;
  text-align: center;
  height: 4rem;
  width: 12rem;
  border-radius: 30px;
  background-color: #F1CEB5;
  color: #000000;
  border: none;
  font-size: 1.5rem;
  font-weight: bolder;
  cursor: pointer;
  text-transform: uppercase;
  overflow: hidden;
  opacity: 1;
}
@media (max-width: 1600px) {
  .card_form_submit {
    height: 5rem;
    width: 15rem;
    font-size: 2rem;
    margin-left: 29rem;
  }
}
@media (max-width: 1300px) {
  .card_form_submit {
    width: 14rem;
    font-size: 1.8rem;
    margin-left: 36rem;
  }
}
@media (max-width: 1025px) {
  .card_form_submit {
    margin-left: 41rem;
    height: 4rem;
    width: 12rem;
    font-size: 1.5rem;
  }
}
@media (max-width: 769px) {
  .card_form_submit {
    margin-left: 26rem;
    height: 6rem;
    width: 18rem;
    font-size: 2rem;
  }
}

.card_form_submit:hover {
  opacity: 1;
  border-radius: 50%;
  height: 4rem;
  width: 12rem;
  background-color: #F1CEB5;
  color: #000000;
}
@media (max-width: 1600px) {
  .card_form_submit:hover {
    height: 5rem;
    width: 15rem;
    font-size: 2rem;
  }
}
@media (max-width: 1300px) {
  .card_form_submit:hover {
    width: 14rem;
    font-size: 1.8rem;
  }
}
@media (max-width: 1024px) {
  .card_form_submit:hover {
    height: 4rem;
    width: 12rem;
    font-size: 1.5rem;
  }
}
@media (max-width: 769px) {
  .card_form_submit:hover {
    height: 6rem;
    width: 18rem;
    font-size: 2rem;
  }
}

.card_form_submit:hover .text-envoyer {
  opacity: 0;
}

.card_form_submit::before {
  content: "C'est parti! →C'est parti! →C'est parti! →C'est parti! →C'est parti! →C'est parti! →C'est parti! →C'est parti! →C'est parti! →";
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  position: absolute;
  animation: scrollCparti 10s linear infinite;
  opacity: 0;
  font-weight: bold;
}

.card_form_submit:hover::before {
  opacity: 1;
  visibility: visible;
  position: absolute;
}

@keyframes scrollCparti {
  0% {
    transform: translateX(-80%);
  }
  /* Commencez à 100% (à droite) */
  100% {
    transform: translateX(-20%);
  }
  /* Terminez à 0% (à gauche) */
}
.card_infos_indication {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 15rem;
  height: 7rem;
  color: #3D3D3D;
  margin-left: 2rem;
  top: 2rem;
}
@media (max-width: 1300px) {
  .card_infos_indication {
    top: 0rem;
  }
}

.card_infos_indication_textone {
  position: relative;
  font-family: "Bison-Bold";
  font-weight: 800;
  letter-spacing: 0;
  font-size: 2rem;
  line-height: normal;
  width: 8.313rem;
  margin-left: 1.5rem;
}
@media (max-width: 1300px) {
  .card_infos_indication_textone {
    font-size: 2rem;
    margin-left: 1.8rem;
  }
}
@media (max-width: 1025px) {
  .card_infos_indication_textone {
    margin-left: 0.3rem;
  }
}
@media (max-width: 768px) {
  .card_infos_indication_textone {
    margin-left: 1.5rem;
  }
}

.card_infos_hr {
  position: relative;
  background-color: #3D3D3D;
  height: 1px;
  font-weight: 900;
  width: 9rem;
  margin-left: 2.9rem;
  margin-top: 0.2rem;
}
@media (max-width: 1300px) {
  .card_infos_hr {
    margin-left: 3.1rem;
  }
}
@media (max-width: 1025px) {
  .card_infos_hr {
    margin-left: 1.8rem;
  }
}
@media (max-width: 768px) {
  .card_infos_hr {
    width: 11rem;
    margin-left: 2.8rem;
  }
}

.card_infos_indication_texttwo {
  position: relative;
  font-family: "Bison-Bold";
  letter-spacing: 0;
  line-height: normal;
  font-size: 5rem;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
  width: 20rem;
  margin-left: 2.2rem;
  margin-top: -1rem;
}
@media (max-width: 1300px) {
  .card_infos_indication_texttwo {
    font-size: 4.5rem;
    margin-left: 1.8rem;
  }
}
@media (max-width: 1025px) {
  .card_infos_indication_texttwo {
    font-size: 3rem;
    left: -3rem;
    margin-left: 0.5rem;
  }
}
@media (max-width: 769px) {
  .card_infos_indication_texttwo {
    font-size: 5rem;
    left: -1rem;
    margin-left: 3.5rem;
  }
}

.close-button {
  position: absolute;
  top: 4rem;
  right: 5rem;
  width: 3rem;
  height: 3rem;
  cursor: pointer;
}
@media (max-width: 1300px) {
  .close-button {
    top: 2rem;
  }
}
@media (max-width: 1024px) {
  .close-button {
    right: 3rem;
  }
}
@media (max-width: 768px) {
  .close-button {
    right: 5rem;
    top: 3rem;
  }
}

.card_button {
  align-items: center;
  bottom: 5rem;
  display: flex;
  height: 6rem;
  justify-content: center;
  background-color: #F1CEB5;
  border-radius: 50%;
  left: 50%;
  opacity: 0;
  position: absolute;
  text-transform: uppercase;
  transform: translateX(-50%);
  transition: opacity 0.4s ease;
  width: 12.4rem;
  z-index: 5;
}
.card_button span {
  display: inline-block;
  line-height: 1;
  position: relative;
  vertical-align: middle;
}
.card--active .card_button {
  opacity: 1;
}
@media (max-width: 1024px) {
  .card_button {
    bottom: 20rem;
    height: 4rem;
    width: 8rem;
    font-size: 1.2rem;
  }
}
@media (max-width: 768px) {
  .card_button {
    width: 12.4rem;
    height: 6rem;
    font-size: 1.8rem;
    top: 5rem;
    transform: translateX(110%);
  }
}

.environnement {
  background-color: #69BBA4;
  transition: opacity 0.5s 0.5s ease, transform 0s 0.5s, visibility 0.5s 0.5s ease;
}
.environnement--active {
  transition-delay: 0;
}

.environnement_wrapper {
  opacity: 0;
  transition: opacity 0.4s ease;
  margin-top: 4rem;
}
.environnement--active .environnement_wrapper {
  opacity: 1;
}
@media (max-width: 768px) {
  .environnement_wrapper {
    display: block;
    margin: auto;
    width: 66.1rem;
  }
}

@media (max-width: 1024px) {
  .environnement_title_wrapper {
    z-index: -1;
  }
}

.environnement_title_text {
  position: absolute;
  font-family: "Bison-Bold";
  color: #F5F4F5;
  font-size: 45rem;
  width: 100%;
  white-space: nowrap;
  top: 11rem;
}
.environnement_title_text span {
  display: inline-block;
  -webkit-animation: newScrolltxt 40s linear infinite;
  animation: newScrolltxt 50s linear infinite;
}
@media (min-width: 1921px) {
  .environnement_title_text {
    top: 13rem;
  }
}
@media (max-width: 1300px) {
  .environnement_title_text {
    top: 17rem;
  }
}
@media (max-width: 1024px) {
  .environnement_title_text {
    font-size: 30rem;
    top: 20rem;
  }
}
@media (max-width: 768px) {
  .environnement_title_text {
    margin-top: 50rem;
  }
}

@media (max-width: 1024px) {
  .environnement_infos_wrapper {
    position: fixed;
    width: 25rem;
    height: 10rem;
    bottom: 0;
    z-index: 1;
  }
}

.environnement_infos {
  position: absolute;
  font-family: "AreaNormal-Black";
  color: #F5F4F5;
  line-height: normal;
  bottom: 5rem;
  margin-left: 5rem;
}
@media (max-width: 1024px) {
  .environnement_infos {
    font-size: 1rem;
    margin-left: 11rem;
    margin-bottom: -4rem;
  }
}

.strong {
  font-family: "AreaNormal-Semibold";
  color: #F5F4F5;
  display: inline;
  line-height: 2rem;
}
@media (max-width: 1024px) {
  .strong {
    line-height: 1rem;
  }
}

.environnement_carousel_wrapper {
  position: relative;
  z-index: 4;
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  gap: 20rem;
  padding-left: 38rem;
  margin-top: -4.5rem;
  height: 75rem;
  width: 145rem;
}
@media (min-width: 1026px) and (max-width: 1850px) {
  .environnement_carousel_wrapper {
    width: 175rem;
  }
}
@media (max-width: 1024px) {
  .environnement_carousel_wrapper {
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100rem;
    height: 42rem;
    padding-left: 11rem;
    display: block;
  }
}
@media (max-width: 1024px) and (max-height: 600px) {
  .environnement_carousel_wrapper {
    height: 35rem;
    gap: 40rem;
    padding-left: 20rem;
  }
}
@media (max-width: 768px) and (min-height: 601px) {
  .environnement_carousel_wrapper {
    height: 35rem;
    padding-left: 6rem;
  }
}
@media (max-width: 768px) and (max-height: 600px) {
  .environnement_carousel_wrapper {
    height: 30rem;
    padding-left: 18rem;
  }
}

.environnement_carousel_wrapper {
  scrollbar-width: none;
}

.environnement_carousel_wrapper::-webkit-scrollbar {
  display: none;
}

.environnement_carousel_media_image {
  width: 41vw;
  height: 81vh;
}
@media (max-width: 1024px) and (max-height: 600px) {
  .environnement_carousel_media_image {
    margin-bottom: 3rem;
  }
}
@media (min-height: 601px) and (max-width: 1300px) {
  .environnement_carousel_media_image {
    width: 75rem;
    height: 75rem;
  }
}
@media (min-height: 601px) and (max-width: 1024px) {
  .environnement_carousel_media_image {
    margin-top: 5rem;
    margin-bottom: 10rem;
    width: 55rem;
    height: 55rem;
  }
}
@media (max-width: 768px) {
  .environnement_carousel_media_image {
    margin-bottom: 2rem;
  }
}

.error_wrapper {
  opacity: 0;
  transition: opacity 0.4s ease;
  margin-top: 4rem;
}
.error--active .error_wrapper {
  opacity: 1;
}
@media (max-width: 768px) {
  .error_wrapper {
    display: block;
    margin: auto;
    width: 66.1rem;
  }
}

@keyframes scrollTxt {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(100%, 0);
  }
}
@media (max-width: 1024px) {
  .error_title_wrapper {
    z-index: -1;
  }
}

.error_title_text {
  position: absolute;
  font-family: "Bison-Bold";
  color: #F5F4F5;
  font-size: 20rem;
  width: 60%;
  white-space: wrap;
  animation: scrollTxt 30s linear infinite;
  top: 2rem;
  z-index: -1;
}
@media (max-width: 1024px) {
  .error_title_text {
    font-size: 20rem;
    top: 10rem;
  }
}

.geologie {
  background-color: #69BBA4;
}
.geologie--active {
  transition-delay: 0;
}

.geologie_wrapper {
  opacity: 0;
  transition: opacity 0.4s ease;
  margin-top: 4rem;
}
.geologie--active .geologie_wrapper {
  opacity: 1;
}
@media (max-width: 768px) {
  .geologie_wrapper {
    display: block;
    margin: auto;
    width: 66.1rem;
  }
}

/* .geologie_title_wrapper{
 }
*/
.geologie_title_text {
  position: absolute;
  font-family: "Bison-Bold";
  color: #F5F4F5;
  font-size: 45rem;
  width: 100%;
  white-space: nowrap;
  top: 11rem;
}
.geologie_title_text span {
  display: inline-block;
  -webkit-animation: newScrolltxt 30s linear infinite;
  animation: newScrolltxt 40s linear infinite;
}
@media (min-width: 1921px) {
  .geologie_title_text {
    top: 13rem;
  }
}
@media (max-width: 1300px) {
  .geologie_title_text {
    top: 17rem;
  }
}
@media (max-width: 1025px) {
  .geologie_title_text {
    font-size: 30rem;
    top: 20rem;
  }
}
@media (max-width: 768px) {
  .geologie_title_text {
    margin-top: 50rem;
  }
}

@media (max-width: 1024px) {
  .geologie_infos_wrapper {
    position: fixed;
    width: 25rem;
    height: 10rem;
    bottom: 0;
    z-index: 1;
  }
}

.geologie_infos {
  position: absolute;
  font-family: "AreaNormal-Black";
  color: #F5F4F5;
  line-height: normal;
  bottom: 5rem;
  margin-left: 5rem;
}
@media (max-width: 1024px) {
  .geologie_infos {
    font-size: 1rem;
    margin-left: 11rem;
    margin-bottom: -4rem;
  }
}

.strong {
  font-family: "AreaNormal-Semibold";
  color: #F5F4F5;
  display: inline;
  line-height: 2rem;
}
@media (max-width: 1024px) {
  .strong {
    line-height: 1rem;
  }
}

.geologie_carousel_wrapper {
  position: relative;
  z-index: 4;
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  gap: 20rem;
  padding-left: 38rem;
  margin-top: -4.5rem;
  height: 75rem;
  width: 145rem;
}
@media (min-width: 1026px) and (max-width: 1850px) {
  .geologie_carousel_wrapper {
    width: 175rem;
  }
}
@media (max-width: 1024px) {
  .geologie_carousel_wrapper {
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100rem;
    height: 42rem;
    padding-left: 11rem;
    display: block;
  }
}
@media (max-width: 1024px) and (max-height: 600px) {
  .geologie_carousel_wrapper {
    height: 35rem;
    gap: 40rem;
    padding-left: 20rem;
  }
}
@media (max-width: 768px) and (min-height: 601px) {
  .geologie_carousel_wrapper {
    height: 35rem;
    padding-left: 6rem;
  }
}
@media (max-width: 768px) and (max-height: 600px) {
  .geologie_carousel_wrapper {
    height: 30rem;
    padding-left: 18rem;
  }
}

.geologie_carousel_wrapper {
  scrollbar-width: none;
}

.geologie_carousel_wrapper::-webkit-scrollbar {
  display: none;
}

.geologie_carousel_media_image {
  width: 41vw;
  height: 81vh;
}
@media (max-width: 1024px) and (max-height: 600px) {
  .geologie_carousel_media_image {
    margin-bottom: 3rem;
  }
}
@media (min-height: 601px) and (max-width: 1300px) {
  .geologie_carousel_media_image {
    width: 75rem;
    height: 75rem;
  }
}
@media (min-height: 601px) and (max-width: 1024px) {
  .geologie_carousel_media_image {
    margin-top: 5rem;
    margin-bottom: 10rem;
    width: 55rem;
    height: 55rem;
  }
}
@media (max-width: 768px) {
  .geologie_carousel_media_image {
    margin-bottom: 2rem;
  }
}

.joindre_wrap {
  overflow: hidden;
  transition: opacity 0.5s 0.5s ease;
}
.joindre--open .joindre_wrap {
  opacity: 0;
  transition-delay: 2s;
  background-color: #1e181a;
}

.joindre_wrapper {
  position: relative;
}
@media (max-width: 1024px) {
  .joindre_wrapper {
    position: sticky;
    top: 0;
  }
}

.joindre_wrapper_caroussel {
  visibility: hidden;
  z-index: -1;
}

.joindre_title_text {
  position: absolute;
  margin-top: 10rem;
  font-family: "Bison-Bold";
  color: #1e181a;
  font-size: 50rem;
  width: 100%;
  white-space: nowrap;
}
.joindre_title_text span {
  display: inline-block;
  -webkit-animation: newScrolltxt 40s linear infinite;
  animation: newScrolltxt 50s linear infinite;
}
@media (min-width: 1921px) {
  .joindre_title_text {
    margin-top: 12rem;
  }
}
@media (max-width: 1300px) {
  .joindre_title_text {
    margin-top: 15rem;
  }
}
@media (max-width: 1025px) {
  .joindre_title_text {
    font-size: 30rem;
    margin-top: 30rem;
  }
}
@media (max-width: 768px) {
  .joindre_title_text {
    margin-top: 70rem;
  }
}

.joindre_wrapper_caroussel {
  position: relative;
  z-index: 4;
  display: flex;
  align-items: center;
  overflow-x: scroll;
  margin-right: auto;
  gap: 20rem;
  height: 100%;
  padding-left: 38rem;
  padding-right: 38rem;
}
@media (max-width: 1024px) {
  .joindre_wrapper_caroussel {
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-left: 11rem;
    padding-right: 0rem;
    display: block;
  }
}
@media (max-width: 1024px) and (max-height: 600px) {
  .joindre_wrapper_caroussel {
    padding-left: 20rem;
  }
}
@media (max-width: 768px) {
  .joindre_wrapper_caroussel {
    padding-left: 10rem;
  }
}

.joindre_wrapper_caroussel::-webkit-scrollbar {
  display: none;
}

.joindre_card_item_image {
  width: 41vw;
  height: 81vh;
}
@media (max-width: 1024px) and (max-height: 600px) {
  .joindre_card_item_image {
    margin-bottom: 3rem;
  }
}
@media (min-height: 601px) and (max-width: 1300px) {
  .joindre_card_item_image {
    width: 75rem;
    height: 75rem;
  }
}
@media (min-height: 601px) and (max-width: 1024px) {
  .joindre_card_item_image {
    margin-top: 5rem;
    margin-bottom: 10rem;
    width: 55rem;
    height: 55rem;
  }
}
@media (max-width: 768px) {
  .joindre_card_item_image {
    margin-bottom: 2rem;
  }
}

.projets_wrapper {
  height: 100%;
  min-width: 100%;
}

.projets_overlap_group {
  align-items: flex-end;
  background-position: 100% 100%;
  background-size: cover;
  display: flex;
  height: 100%;
  min-width: 100%;
  position: relative;
}

.projets_link_retour {
  position: absolute;
  left: 1rem;
  top: 0.5rem;
  z-index: 1;
}

.btn_retour {
  text-decoration: none;
  color: #ffffff;
}

.projets_title {
  position: absolute;
  font-family: "Bison-Bold";
  font-style: normal;
  font-weight: 300;
  font-size: 15.313rem;
  line-height: normal;
  color: #ebe8e7;
  top: 25rem;
  left: 7rem;
  z-index: 1;
  width: 40.671rem;
  height: 16.813rem;
}
@media (max-width: 768px) {
  .projets_title {
    top: 40%;
    font-size: 20rem;
  }
}

.projets_deco_one {
  top: 0;
  left: 0;
  pointer-events: none;
  position: absolute;
  width: 37.438rem;
  height: 34rem;
}

.projets_deco_two {
  pointer-events: none;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 90rem;
  height: 44rem;
}

.projets_img_construction {
  pointer-events: none;
  position: absolute;
  left: 50rem;
  top: 10rem;
  width: 22rem;
  height: 19rem;
}
@media (max-width: 1024px) {
  .projets_img_construction {
    top: 10%;
  }
}
@media (max-width: 768px) {
  .projets_img_construction {
    top: 20%;
  }
}

.projets_img_arrow {
  pointer-events: none;
  position: absolute;
  top: 27rem;
  left: 2rem;
  width: 3.688rem;
  height: 4.75rem;
}
@media (max-width: 768px) {
  .projets_img_arrow {
    top: 45%;
    width: 4rem;
    height: 5rem;
  }
}

.sinistre {
  background-color: #69BBA4;
  transition: opacity 0.5s 0.5s ease, transform 0s 0.5s, visibility 0.5s 0.5s ease;
}
.sinistre--active {
  transition-delay: 0;
}

.sinistre_wrapper {
  opacity: 0;
  transition: opacity 0.4s ease;
  margin-top: 4rem;
}
.sinistre--active .sinistre_wrapper {
  opacity: 1;
}
@media (max-width: 768px) {
  .sinistre_wrapper {
    display: block;
    margin: auto;
    width: 66.1rem;
  }
}

@media (max-width: 1024px) {
  .sinistre_title_wrapper {
    z-index: -1;
  }
}

.sinistre_title_text {
  position: absolute;
  font-family: "Bison-Bold";
  color: #F5F4F5;
  font-size: 45rem;
  width: 100%;
  white-space: nowrap;
  top: 11rem;
}
.sinistre_title_text span {
  display: inline-block;
  -webkit-animation: newScrolltxt 30s linear infinite;
  animation: newScrolltxt 40s linear infinite;
}
@media (min-width: 1921px) {
  .sinistre_title_text {
    top: 13rem;
  }
}
@media (max-width: 1300px) {
  .sinistre_title_text {
    top: 17rem;
  }
}
@media (max-width: 1024px) {
  .sinistre_title_text {
    font-size: 30rem;
    top: 20rem;
  }
}
@media (max-width: 768px) {
  .sinistre_title_text {
    margin-top: 50rem;
  }
}

@media (max-width: 1024px) {
  .sinistre_infos_wrapper {
    position: fixed;
    width: 25rem;
    height: 10rem;
    bottom: 0;
    z-index: 1;
  }
}

.sinistre_infos {
  position: absolute;
  font-family: "AreaNormal-Black";
  color: #F5F4F5;
  line-height: normal;
  bottom: 5rem;
  margin-left: 5rem;
}
@media (max-width: 1024px) {
  .sinistre_infos {
    font-size: 1rem;
    margin-left: 11rem;
    margin-bottom: -4rem;
  }
}

.strong {
  font-family: "AreaNormal-Semibold";
  color: #F5F4F5;
  display: inline;
  line-height: 2rem;
}
@media (max-width: 1024px) {
  .strong {
    line-height: 1rem;
  }
}

.sinistre_carousel_wrapper {
  position: relative;
  z-index: 4;
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  gap: 20rem;
  padding-left: 38rem;
  margin-top: -4.5rem;
  height: 75rem;
  width: 145rem;
}
@media (min-width: 1026px) and (max-width: 1850px) {
  .sinistre_carousel_wrapper {
    width: 175rem;
  }
}
@media (max-width: 1024px) {
  .sinistre_carousel_wrapper {
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100rem;
    height: 42rem;
    padding-left: 11rem;
    display: block;
  }
}
@media (max-width: 1024px) and (max-height: 600px) {
  .sinistre_carousel_wrapper {
    height: 35rem;
    gap: 40rem;
    padding-left: 20rem;
  }
}
@media (max-width: 768px) and (min-height: 601px) {
  .sinistre_carousel_wrapper {
    height: 35rem;
    padding-left: 6rem;
  }
}
@media (max-width: 768px) and (max-height: 600px) {
  .sinistre_carousel_wrapper {
    height: 30rem;
    padding-left: 18rem;
  }
}

.sinistre_carousel_wrapper {
  scrollbar-width: none;
}

.sinistre_carousel_wrapper::-webkit-scrollbar {
  display: none;
}

.sinistre_carousel_media_image {
  width: 41vw;
  height: 81vh;
}
@media (max-width: 1024px) and (max-height: 600px) {
  .sinistre_carousel_media_image {
    margin-bottom: 3rem;
  }
}
@media (min-height: 601px) and (max-width: 1300px) {
  .sinistre_carousel_media_image {
    width: 60rem;
    height: 63rem;
  }
}
@media (min-height: 601px) and (max-width: 1024px) {
  .sinistre_carousel_media_image {
    margin-top: 5rem;
    margin-bottom: 10rem;
    width: 55rem;
    height: 55rem;
  }
}
@media (max-width: 768px) {
  .sinistre_carousel_media_image {
    margin-bottom: 2rem;
  }
}