.error{
    @extend %page;
    &--active {
        @extend %page--active;
    }
}

.error_wrapper {
    opacity: 0;
    transition: opacity 0.4s ease;
  
    .error--active & {
      opacity: 1;
    }
    margin-top: 4rem;
    @include media('<phone') {
      display: block;
      margin: auto;
      // padding: 15rem 0 31.5rem;
      width: 66.1rem;
    }
}

@keyframes scrollTxt{
    0%{
      transform: translate(0,0);
    }
    100%{
        transform: translate(100%,0);
    }
}

.error_title_wrapper{
    @include media('<tablet'){
        // position: sticky;
        // top: 0;
        z-index: -1;
    }
}
  
.error_title_text{
    position:absolute;
    font-family: $font-family-bison-bold;
    color: $color-violet-clear;
    font-size:20rem;
    width: 60%;
    white-space: wrap;
    animation: scrollTxt 30s linear infinite;
    top: 2rem;
    z-index: -1;
    // border: 1px solid black;

    @include media('<tablet'){
        font-size:20rem;
        // position: sticky;
        top: 10rem;
        // z-index: -1;
    }
}