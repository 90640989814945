.menu_overlay_wrapper{
    background-color: $color-blue;
    background-size: cover;
    background-position: 100% 100%;
    height: 100%;
    width: 0;
    position: fixed;
    top: 0;
    left: 0;
    overflow-x: hidden;
    transition: 0.5s;
    z-index: z('menu');

    @include media('<tablet') {
        background-size: 150%;
        background-repeat: no-repeat;
        background-position: center;
    }
}

.menu_overlap_group_content {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 60rem;
    pointer-events: cursor;
    top: 2rem;

    @include media('width>1920px','width<2300px'){
        top: 5rem;
    }

    @include media('width<=1920px','width>1700px'){
        top: 1rem;
    }

    @include media('width>=1680px','width<1700px') {
        top: 10rem;
    }

    @include media('width<=1500px') {
        top: 5rem;
    }

    @include media('<tablet'){
        left: 20rem;
        top: 10rem;
    }

    @include media('<phone'){
        top: 35rem;
        left: 40rem;
        right:50rem;
    }

}

.menu_overlay_link{
    display: flex;
    gap: 3rem;
    justify-content: center;
    transition: 0.1s;
    @include media('<tablet'){ 
       margin-left: -8rem;
    }
    @include media('<phone'){
        width: 50rem;
        margin-left: -26rem;
        justify-content: flex-start;
    }
}

.menu_overlay_link_services {
    display: grid;
    grid-template-rows: 10rem 2rem;
}

.menu_srv_title {
    display: flex;
    gap: 3rem;
}

.submenu_services {
    display: none;
    // border: 1px solid black;
    width: 20rem;
    justify-content: flex-start;
    @include media('<phone'){
        margin-top: 2rem;
    }
}

.submenu_block a, .menu_accueil, .menu_services, .menu_realisations, .menu_projets, .menu_contact  {
    letter-spacing: 0;
    line-height: normal;
    color: $color-gray-nurse;
    font-family: $font-family-bison-bold;
    font-style: normal;
    font-weight: 700;
    transition: 0.5s;
    text-decoration: none;
    z-index: 5;
}

.submenu_block a {
    position: relative;
    font-size: 3rem;
    left: 1rem;
    @extend %link--hidden;
    @include media('<phone'){
        font-size: 5rem;
    }
}

.menu_accueil, .menu_services, .menu_realisations, .menu_projets, .menu_contact {
    font-size: 12rem;
    @include media('<phone'){
        font-size: 15rem;
    }
}

.menu_fleche {
    width: 12rem;
    height: 7.5rem;
    margin-top: auto;
    margin-bottom: auto;
    display: none;
    pointer-events: none;
}

.menu_deco{
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 454px;
    pointer-events: none;

    @include media('height<=643px') {
        height: auto;
    }

    @include media('<tablet') {
        display: none;
    }
}

.menu_deco_phone{
    pointer-events: none;
    display: none;
    @include media('<tablet') {
        display: block;
    }
}


.menu_closebtn{
    position: absolute;
    top: 0px;
    left: 0px;
    margin-top: 0.5rem;
    margin-left: 3rem;
    cursor: pointer;
}

.menu_group2_facebook, .menu_group2_linkedin, .menu_group2_email{
    @extend %link--hidden;
    line-height: normal;
    color: $color-white;
    font-family: $font-family-helvetica_neue-regular; // a changer area bold
    font-size: 2rem;
    font-style: normal;
    font-weight: 900;
    letter-spacing: 1px;
    text-decoration: none;
    z-index: 5;
    @include media('<phone'){
        font-size: 3rem;
    }
}
.menu_group2_email {
    @include media('<tablet'){
        width: 6rem;
    }
    @include media('<phone'){
        width: 9rem;
    }
}

.menu_group2_linkedin {
    @include media('<tablet'){
        width: 10rem;
    }
    @include media('<phone'){
        width: 13rem;
    }
}

.menu_links_wrapper{
    display: flex;
    margin-top: 4rem;
    gap: 5rem;

    @include media('<tablet'){
        // display: grid;
        width: 50rem;
        justify-content: center;
        margin-left: -10rem;
        text-align: center;
    }

    @include media('<phone'){
        margin-top: 10rem;
        margin-left: -30rem;
    }
}

.menu_shape {
    pointer-events: none;
    right: 0;
    object-fit: cover;
    position: absolute;
    bottom: 0;

    @include media('>tablet','height<=643px') {
        top: 650px;
    }

    @include media('<tablet') {
        top: 1000px;
    }
}
