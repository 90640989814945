.pieds {
    position: absolute;
    display: flex;
    // z-index: z('footer');
    bottom: 0rem;
    @include media('<tablet'){
      bottom: 0rem;
      height: 100px;
    }
}

.pieds_links_wrapper {
    position: relative;
    margin-left: 60rem;
    display: flex;
    width: 28rem;
    height: 3rem;
    text-align: center;
    gap: 4rem;
    bottom: 0rem;
    z-index: z('footer');
    @include media('<tablet'){
        position:fixed;
        // bottom: 12rem;
        margin-left: 28rem;
        gap: 3rem;
    }

    @include media('<phone'){
      // bottom: 22rem;
      margin-left: 23rem;
    }
}

.pieds_link{
    color: $color-black;
    font-family: $font-family-helvetica_neue-regular;// a changer area normal bold
    font-size: 1.7rem;
    font-style: normal;
    font-weight: 900;
    letter-spacing: 1px;
    text-decoration: none;
    z-index: z('footer');
    height: 1.8rem;
    @extend %link--hidden;
    @include media('<tablet'){
      font-size: 1.3rem;
      height: 1.5rem;
    }
    @include media('<phone'){
      font-size: 2.3rem;
      height: 2.5rem;
    }
}

.pieds_media_background_black, .pieds_media_background_white {
    pointer-events: none;
    position:fixed;
    height: 20rem;
    width: 40rem;
    right: 0;
    bottom: 0rem;
    z-index: z('content');
}

/*
*  css informations disponibilités footer-reseau-common
*/

.pieds_infos_wrapper {
    z-index: z('content');
    @include media('<tablet'){
      position: fixed;
      width: 25rem;
      height: 10rem;
      bottom: 0;
      display: none;
    }
  }

  .pieds_infos{
    position:absolute;
    font-family: $font-family-areaNormal-black;
    color: $color-violet-clear;
    line-height: normal;
    bottom: 5rem;
    margin-left: 5rem;

    @include media('<tablet'){
      font-size: 1.3rem;
      margin-left: 11rem;
      margin-bottom: -4rem;
    }
    @include media('<phone'){
      font-size: 2.3rem;
      width: 50rem;
    }
  }
  /*
  .strong {
    font-family: $font-family-areaNormal-semibold;
    color: $color-violet-clear;
    display: inline;
    line-height: 2rem;
    @include media('<tablet'){
      line-height: 1rem;
    }
    @include media('<phone'){
      line-height: 1.8rem;
    }
  }
  */
