.preloader {
  @extend %center;
  @extend %cover;

  background: $color-blue;
  color: $color-white;
  text-align: center;
  z-index: z('preloader');
}

.preloader_relative{
  position: relative;
  height:100%;
  width: 100%;
  @extend %center;
}

.preloader__text {
  position: absolute;
  font-family:$font-family-bison-bold;
  font-style: normal;
  font-weight: 300;
  font-size: 15.313rem;
  line-height: normal;
  color: $color-gray-nurse;
  top: 30%;
  max-width: 67rem;
  opacity: 0;

  span span {
    font-family:$font-family-bison-bold;
    font-style: normal;
    font-weight: 300;
    font-size: 15.313rem;
    line-height: normal;

    display: inline-block;
    transform-origin: center center 0.5em;
  }

  @include media('<tablet') {
    top: 10rem;
  }

  @include media('<phone') {
    top: 30%;
  }
}

.preloader__number {
  font-size: 4rem;
  bottom: 7.3rem;
  left: 50%;
  overflow: hidden;
  position: absolute;
  transform: translateX(-50%);

  @include media('<tablet') {
    font-size: 3rem;
  }

  @include media('<phone') {
    font-size: 2.4rem;
  }
}

.preloader__number__text {
  display: inline-block;
}