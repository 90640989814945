$color-bright-gray: #37384C;
$color-cadet-blue: #B2B8C3;
$color-contessa: #C97164;
$color-quicksand: #BC978C;
$color-quarter-spanish-white: #F9F1E7;
$color-white: #fff;
$color-black: #000000;
$color-eerie-black: #1e181a;
$color-mercury: #e6e6e5;
$color-tundora: #444444;
$color-gray-nurse: #ebe8e7;
$color-white: #ffffff;
$color-alto: #d9d9d9;
$color-cararra: #ece8e7;// color body
$color-blue: #2756cf; // color project page
$color-pink:  #F1CEB5; // color preloader
$color-gris-form: #f4f4f5;
$color-green-sea: #69BBA4; // color details page
$color-violet-clear: #F5F4F5;
$color-gray-contact: #3D3D3D;

$font-george-x: 'George X';
$font-suisse-bp: 'Suisse-bp';
$font-suisse-bp-ultra: 'suisse-bp-ultra';
$font-suisse-bp-regular: 'suisse-bp-regular';
$font-family-bison-bold: "Bison-Bold";
$font-family-helvetica-bold: "Helvetica-Bold";
$font-family-helvetica-regular: "Helvetica-Regular";
$font-family-helvetica_neue-regular: "Helvetica-Neue-Regular";
$font-family-areaNormal-bold:"AreaNormal-Bold";
$font-family-areaNormal-black:"AreaNormal-Black";
$font-family-areaNormal-semibold:"AreaNormal-Semibold";

$font-family-area-regular:"Area-Regular";
$font-family-area-bold:"Area-Bold";
$font-family-area-semi-bold:"Area-Semibold";

$ease-in-out: cubic-bezier(0.77, 0, 0.175, 1);

$z-indexes: (
  'preloader',
  'transition',
  'menu',
  'entete',
  'footer',
  'canvas',
  'content'
);
