%description-12 {
  font-size: 1.2rem;

  @include media('<tablet') {
    font-size: 3rem;
  }
}

%description-14 {
  font-size: 1.4rem;

  @include media('<tablet') {
    font-size: 3rem;
  }
}

%description-18 {
  font-size: 1.8rem;
  font-weight: 300;
  //line-height: 1.5;

  @include media('<tablet') {
    font-size: 2.8rem;
  }
}
